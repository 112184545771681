import { Component } from '@angular/core';
import { CurrencyService } from '../../services/currency.service';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss']
})
export class CurrencySelectorComponent {

  currencies: Array<{ name: string, description: string }> = [];

  constructor(private currencyService: CurrencyService) { }

  setCurrency(name: string) {
    this.currencyService.setCurrency(name);
    setTimeout(() => window.location.reload(), 200);
  }

}
