import { Injectable } from "@angular/core";
import { QrCode } from "../models/qr-code";

@Injectable()
export class PaymentQrCodeAdapter {

  constructor() { }

  adapter(raw: any): QrCode {
    const qrCode: QrCode = {
      code: raw['qrCode'] || null,
    };
    return qrCode;
  }

}
