import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Company } from 'src/app/core/models/company';
import { CompanyService } from 'src/app/core/services/company.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ProductAdapter } from '../adapters/product.adapter';
import { Product, SearchProducts } from '../models/product';
import { TypePayment } from '../models/type-payment';
import { FeaturedProductsAdapter } from '../adapters/featured-products.adapters';
import { FeaturedProducts } from '../models/featured-products';
import { ProdutoLog } from  'src/app/shared/enums/produto-log';

@Injectable()
export class ProductsService {

  private readonly PRODUCT_URL = '/produtoLoja';
  private readonly PESSOA_URL = '/pessoaLoja';

  static ProductAdapter: any;
  private company: Company | null;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private companyService: CompanyService,
    private productAdapter: ProductAdapter,
    private oFeaturedProductsAdapter: FeaturedProductsAdapter
  ) {
    this.company = this.companyService.company;
  }

  getProductListByRequest(search: SearchProducts): Observable<Product[]> {
    const oParams = {} as any;

    oParams['sgPessoaSiaf'] = this.company?.id;
    oParams['flComplementares'] = 'N';
    oParams['tpClassificador'] = 'PROD';
    if (search) {
      if (search.types?.length) {
        oParams['noDado'] = JSON.stringify(search.types);
      }
      if (search.tags?.length) {
        oParams['noDadoTag'] = JSON.stringify(search.tags);
      }
      if (search.name) {
        oParams['noClassificador'] = search.name;
      }
      if (search.order) {
        oParams['tpOrdenacao'] = search.order;
      }
      if (search.pagTam) {
        oParams['pagIni'] = search.pagIni;
        oParams['pagTam'] = search.pagTam;
      }
    }

    return this.http.get<any>(`${this.PRODUCT_URL}/lstProdutos`, { headers: this.utilsService.requestPublic(), params: oParams }).pipe(map(item => {
      if (item.lines && item.lines.length) {
        return item.lines.map((product: any) => this.productAdapter.adapter(product));
      }
      return [];
    }));
  }

  getProductsSectionListByRequest(): Observable<FeaturedProducts[]> {
    const oParams = {} as any;

    return this.http.get<any>(`${this.PRODUCT_URL}/getProdutoSecao`, { headers: this.utilsService.requestPublic(), params: oParams }).pipe(map(item => {
      if (item.lines && item.lines.length) {
        return item.lines.map((section: any) => this.oFeaturedProductsAdapter.adapter(section));
      }
      return [];
    }));
  }

  geProducttDetailsRequest(idClassificador: number): Observable<Product> {
    const oParams = {} as any;

    oParams['sgPessoaSiaf'] = this.company?.id;
    oParams['idClassificador'] = idClassificador;

    return this.http.get<any>(`${this.PRODUCT_URL}/lstProdutoDet`, { headers: this.utilsService.requestPublic(), params: oParams }).pipe(map(item => {
      if (item && item.produtos) {
        return this.productAdapter.adapter(item.produtos);
      }
      return {} as Product;
    }));
  }


  insProdutoLog(idClassificador: number, produtoLog: string): Observable<Product> {
    const oParams = {} as any;

    oParams['sgDadoAcao'] = produtoLog;
    oParams['idClassificador'] = idClassificador;
    const headers = this.utilsService.requestPublic();
      return this.http.post<any>(`${this.PRODUCT_URL}/insProdutoLog`, oParams, { headers }).pipe(map(item => {
          if (item && item.produtos) {
              return this.productAdapter.adapter(item.produtos);
          }
          return {} as Product;
      }));
}

  getTypePayment(): Observable<TypePayment[]> {
    const oParams = {} as any;

    return this.http.get<any>(`${this.PESSOA_URL}/lstFomasPgto`, { headers: this.utilsService.requestPublic(), params: oParams }).pipe(map(item => {
      if (item && item.lines) {
        this.alterarValorMultiCaixaExpress(item);
        return item.lines.map((payment: any) => this.productAdapter.adapterPayment(payment));
      }
      return [];
    }));
  }
  alterarValorMultiCaixaExpress(jsonData: any): void {
    for (const line of jsonData.lines) {
      if (line.dsFormaPgto === "Multi-caixa") {
        line.dsFormaPgto = "Multicaixa Express";
      }
    }
  }

}
