import { Injectable } from "@angular/core";
import { Domain } from "../models/domain";

@Injectable()
export class DomainAdapter {

  constructor() { }

  adapter(raw: any): Domain {
    const domain: Domain = {
      type: raw['codigo'] || null,
      name: raw['descricao'] || null,
    };
    return this.validate(domain);
  }

  private validate(domain: Domain): Domain {
    if (!domain.type || !domain.name) {
      throw new Error('Error creating filter, all attributes must be filled in.');
    }
    return domain;
  }

}
