<!-- Footer Start -->
<footer class="section-t-space footer-section-2 mt-5" >
  <div class="container">
    <div class="service-section">
      <div class="row g-3">
        <div class="row">
          <div class="col-12 d-flex flex-row">
            <div class="service-contain col-md-12 custom-service-box">
              <div class="service-box">
                <div class="service-image">
                  <img src="./assets/images/frete.png" class="blur-up lazyload" alt="">
                </div>
                <div class="service-detail">
                  <h5>{{deliveryLocation}}</h5>
                  <span class="ext-muted w-md-85 custom-text"
                    style="word-wrap: break-word; white-space: pre-line;">{{deliveryLocationDescription}}</span>
                </div>

              </div>
              <div class="service-box">
                <div class="service-image">
                  <img src="./assets/images/frete.png" class="blur-up lazyload" alt="">
                </div>

                <div class="service-detail">
                  <h5>{{freight}}</h5>
                  <span class="ext-muted w-md-85">{{freightDescription}}</span>
                </div>
              </div>
              <div class="service-box">
                <div class="service-image">
                  <img src="./assets/images/frete.png" class="blur-up lazyload" alt="">
                </div>

                <div class="service-detail">
                  <h5>{{securePayment}}</h5>
                  <span class="ext-muted w-md-85">{{securePaymentDescription}}</span>
                </div>
              </div>
              <div class="service-box">
                <div class="service-image">
                  <img src="./assets/images/frete.png" class="blur-up lazyload" alt="">
                </div>

                <div class="service-detail">
                  <h5>{{talkToUs}}</h5>
                  <span class="ext-muted w-md-85">{{talkToUsDescription}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid-lg">
    <div class="main-footer">
      <div class="row g-md-4 gy-sm-5 gy-2">
        <div class="col-xxl-3 col-xl-4 col-sm-6">
          <a>
            <img [src]="imageLogo ? carregarProduto(imageLogo) : './assets/images/Red-Alert-Transparent-PNG.png'"
              style="max-height: 60px;" class="img-fluid blur-up lazyload" alt="">
          </a>
          <p class="information-text">{{ textPromotional }}</p>

          <ul class="social-icon">
            <ul *ngIf="linkedin">
              <li>
                <a [href]="linkedin" target="_blank">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
            <ul *ngIf="facebook">
              <li>
                <a [href]="facebook" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
            </ul>
            <ul *ngIf="instagram">
              <li>
                <a [href]="instagram" target="_blank">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
            <!-- <ul *ngFor="let line of data.lines">
              <li>
                <a *ngIf="line.opcoes[0]?.descricao" [href]="line.opcoes[0].descricao">
                  <i class="fa"></i>
                  <i *ngIf="line.sgTpParametroSistema === 'TP_LINK_LINKEDIN'" class="fab fa-linkedin-in"></i>
                  <i *ngIf="line.sgTpParametroSistema === 'TP_LINK_FACEBOOK'" class="fab fa-facebook-f"></i>
                  <i *ngIf="line.sgTpParametroSistema === 'TP_LINK_INSTAGRAM'" class="fab fa-instagram"></i>
                </a>
              </li>
            </ul> -->
          </ul>

          <div class="social-app mt-sm-12 mt-3 mb-4" style="display: flex; flex-direction: row;">
            <ul>
              <li>
                <a *ngIf="playStore" [href]="playStore" target="_blank">
                  <img *ngIf="playStore" style="height: 80px; width: 120px;" class="blur-up lazyload w-80 h-80" img
                    src="./assets/images/playstore.svg">
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a *ngIf="appleStore" [href]="appleStore" target="_blank">
                  <img *ngIf="appleStore" style="height: 80px; width: 120px;" class="blur-up lazyload w-80 h-80" img
                    src="./assets/images/appstore.svg">
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-xxl-2 col-xl-4 col-sm-6">
          <div class="footer-title">
            <h4>{{ 'footer.institutional' | translate }}</h4>
          </div>
          <ul class="footer-list footer-contact mb-sm-0 mb-3">
            <li>
              <a [routerLink]="['/exchanges-returns']" routerLinkActive="router-link-active" class="footer-contain-2">
                <i class="fas fa-angle-right"></i>{{titleTrade}}</a>
            </li>
            <li>
              <a [routerLink]="['/terms-conditions']" routerLinkActive="router-link-active" class="footer-contain-2">
                <i class="fas fa-angle-right"></i>{{ titleTermsConditions }}</a>
            </li>
            <li>
              <a [routerLink]="['/about-us']" routerLinkActive="router-link-active" class="footer-contain-2">
                <i class="fas fa-angle-right"></i>{{ titleAboutUs }}</a>
            </li>
            <li>
              <a [routerLink]="['contact']" routerLinkActive="router-link-active" class="footer-contain-2">
                <i class="fas fa-angle-right"></i>{{'footer.contact_us' | translate}}</a>
            </li>
          </ul>
        </div>

        <div class="col-xxl-3 col-xl-4 col-sm-6">
          <div class="footer-title">
            <h4>{{ 'footer.store_information' | translate }}</h4>
          </div>
          <ul class="footer-address footer-contact">
            <li>
              <a href="javascript:void(0)">
                <div class="inform-box flex-start-box">
                  <i data-feather="map-pin"></i>
                  <p><b>Jupiter | Systems & Solutions</b>,<br> {{addressDescription}}</p>
                </div>
              </a>
            </li>

            <li>
              <a href="javascript:void(0)">
                <div class="inform-box">
                  <i data-feather="phone"></i>
                  <p>{{ phoneConact }}: {{phoneConactDescription}}</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="sub-footer section-small-space">
      <div class="left-footer">
        <p>{{ 'footer.copyright' | translate }}</p>
      </div>
    </div>
  </div>
</footer>
<!-- Footer End -->
