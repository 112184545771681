import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { UserCredential } from "../models/user-credential";

@Injectable({
  providedIn: 'root'
})
export class CredentialAdapter {

  constructor(
  ) { }

  public adapter(raw: any): UserCredential {
    const user: UserCredential = {
      access_token: raw['access_token'] || null,
      refresh_token: raw['refresh_token'] || null,
      token_type: raw['token_type'] || null,
      id_token: raw['id_token'] || null,
      expires_in: raw['expires_in'] || null,
      scope: raw['scope'] || null,
      created_at: raw['created_at'] || null
    };
    return this.validate(user);
  }

  public validate(userCredential: UserCredential): UserCredential {
    if (!userCredential.access_token || !userCredential.refresh_token || !userCredential.token_type || !userCredential.id_token || !userCredential.scope || !userCredential.created_at) {
      throw new Error('Error creating user, all required attributes must be filled in.');
    }
    return userCredential;
  }

}
