import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonGroupOption } from '../../model/button-group-option';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss']
})
export class ButtonGroupComponent {

  @Input() list: ButtonGroupOption[] = [];
  @Input() isActiveValue = null;
  @Output() onSelected: EventEmitter<ButtonGroupOption> = new EventEmitter<ButtonGroupOption>();


  constructor() { }

  selectValue(value: ButtonGroupOption) {
    this.onSelected.emit(value);
  }

}
