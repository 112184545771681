import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private readonly LANG_KEY = 'LANGUAGE';
  private readonly LANG_DEFAULT = 'pt';

  constructor(
    private translate: TranslateService
  ) { }

  init() {
    this.setLanguage(this.getLanguage());
    this.translate.setDefaultLang(this.getLanguage());
    this.translate.use(this.getLanguage());
  }

  getLanguage(): string {
    const language = localStorage.getItem(this.LANG_KEY);
    return language ? language : this.LANG_DEFAULT;
  }

  private setLanguage(language: string): void {
    localStorage.setItem(this.LANG_KEY, language);
  }

  setLanguageToPT(): void {
    this.setLanguage('pt');
  }

  setLanguageToEN(): void {
    this.setLanguage('en');
  }

  isLanguagePT(): boolean {
    return this.getLanguage() === 'pt';
  }

  isLanguageEN(): boolean {
    return this.getLanguage() === 'en';
  }
}
