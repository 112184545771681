import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SlickSliderService {
  private productSlider: any; // Referência ao objeto Slick do slider de produtos dinâmico
  private autoplayPaused: boolean = false; // Flag para controlar se o autoplay está pausado
  private autoplayTimeout: any; // Referência ao temporizador de reinício do autoplay

  private categorySlider: any; // Referência ao objeto Slick do slider de categorias
  private categoryAutoplayPaused: boolean = false; // Flag para controlar se o autoplay está pausado
  private categoryAutoplayTimeout: any; // Referência ao temporizador de reinício do autoplay

  constructor() { }

  templateLoadCategorySlider() {
    $(document).ready(() => {
      this.categorySlider = $('.category-slider').slick({
        arrows: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1745,
            settings: {
              slidesToShow: 7,
            }
          },
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: 6,
            }
          },
          {
            breakpoint: 1124,
            settings: {
              slidesToShow: 5,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 692,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 482,
            settings: {
              slidesToShow: 2,
            }
          },
        ]
      });

      // Adiciona um evento para detectar quando as setas do slider são clicadas
      $('.category-slider .slick-arrow').on('click', () => {
        // Pausa o autoplay quando uma seta é clicada
        if (!this.categoryAutoplayPaused) {
          this.categorySlider.slick('slickPause');
          this.categoryAutoplayPaused = false;

          // Reinicia o autoplay após 5 segundos de inatividade
          this.resetCategoryAutoplayTimeout();
        }
      });

      // Adiciona um evento para detectar quando o mouse sai do slider
      $('.category-slider').on('mouseleave', () => {
        // Reinicia o autoplay após 5 segundos de inatividade
        this.resetCategoryAutoplayTimeout();
      });

      // Adiciona um evento para detectar quando o mouse entra no slider
      $('.category-slider').on('mouseenter', () => {
        // Cancela o temporizador de reinício do autoplay
        clearTimeout(this.categoryAutoplayTimeout);

        // Se o autoplay estiver pausado, reinicia
        if (this.categoryAutoplayPaused) {
          this.categorySlider.slick('slickPlay');
          this.categoryAutoplayPaused = false;
        }
      });
    });
  }

  private resetCategoryAutoplayTimeout() {
    // Cancela o temporizador se existir
    clearTimeout(this.categoryAutoplayTimeout);

    // Inicia um novo temporizador para reiniciar o autoplay após 5 segundos de inatividade
    this.categoryAutoplayTimeout = setTimeout(() => {
      if (!this.categoryAutoplayPaused) {
        this.categorySlider.slick('slickPlay');
        this.categoryAutoplayPaused = false;
      }
    }, 500); // 5000 milissegundos = 5 segundos
  }

  templateLoadDinamicProductSlider() {
    $(document).ready(() => {
      this.productSlider = $('.dinamic-product-slider').slick({
        arrows: true,
        infinite: true,
        slidesToShow: 6,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1745,
            settings: {
              slidesToShow: 5,
            }
          },
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 1124,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 692,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 482,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
      });

      // Adiciona um evento para detectar quando as setas do slider são clicadas
      $('.dinamic-product-slider .slick-arrow').on('click', () => {
        // Pausa o autoplay quando uma seta é clicada
        if (!this.autoplayPaused) {
          this.productSlider.slick('slickPause');
          this.autoplayPaused = false;

          // Reinicia o autoplay após 5 segundos de inatividade
          this.resetAutoplayTimeout();
        }
      });

      // Adiciona um evento para detectar quando o mouse sai do slider
      $('.dinamic-product-slider').on('mouseleave', () => {
        // Reinicia o autoplay após 5 segundos de inatividade
        this.resetAutoplayTimeout();
      });

      // Adiciona um evento para detectar quando o mouse entra no slider
      $('.dinamic-product-slider').on('mouseenter', () => {
        // Cancela o temporizador de reinício do autoplay
        clearTimeout(this.autoplayTimeout);

        // Se o autoplay estiver pausado, reinicia
        if (this.autoplayPaused) {
          this.productSlider.slick('slickPlay');
          this.autoplayPaused = false;
        }
      });
    });
  }

  private resetAutoplayTimeout() {
    // Cancela o temporizador se existir
    clearTimeout(this.autoplayTimeout);

    // Inicia um novo temporizador para reiniciar o autoplay após 5 segundos de inatividade
    this.autoplayTimeout = setTimeout(() => {
      if (!this.autoplayPaused) {
        this.productSlider.slick('slickPlay');
        this.autoplayPaused = false;
      }
    }, 500); // 5000 milissegundos = 5 segundos
  }


  templateLoadDinamicProductSliderDayEmphasis() {
    $(document).ready(() => {
      $('.dinamic-product-slider-emphasis').slick({
        arrows: true,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1745,
            settings: {
              slidesToShow: 5,
            }
          },
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 1124,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 692,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 482,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
      });
    });
  }


  closeAddCartBox() {
    $(document).ready(() => {
      $(".add-cart-box .view-button").click(() => {
        $(".add-cart-box").removeClass("show");
      });

      $(".add-cart-box .add-cart-button").click(() => {
        $(".add-cart-box").removeClass("show");
      });
    });
  }

  addToWishlist() {
    $(document).ready(() => {
      $(".notifi-wishlist").on("click", function () {
        $.notify({
          icon: "fa fa-check",
          title: "Success!",
          message: "Item Successfully added in wishlist",
        }, {
          element: "body",
          position: null,
          type: "info",
          allow_dismiss: true,
          newest_on_top: false,
          showProgressbar: true,
          placement: {
            from: "top",
            align: "right",
          },
          offset: 20,
          spacing: 10,
          z_index: 1031,
          delay: 5000,
          animate: {
            enter: "animated fadeInDown",
            exit: "animated fadeOutUp",
          },
          icon_type: "class",
          template: '<div data-notify="container" class="col-xxl-3 col-lg-5 col-md-6 col-sm-7 col-12 alert alert-{0}" role="alert">' +
            '<button type="button" aria-hidden="true" class="btn-close" data-notify="dismiss"></button>' +
            '<span data-notify="icon"></span> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-info progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            "</div>" +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            "</div>",
        });
      });
    });
  }
}
