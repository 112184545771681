import { Injectable } from "@angular/core";
import { ApplicationCredential } from "../models/application-credential";
import { UtilsService } from "src/app/shared/services/utils.service";
import { TokenAdapter } from "../adapters/token.adapter";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { CompanyRepository } from "./company.repository";
import { settings } from "src/settings";

@Injectable({
  providedIn: 'root'
})
export class TokenRepository {
  private readonly TOKEN_APPLICATION = 'TOKEN_APPLICATION';
  private readonly API_TOKEN = '/token';

  tokenSource = new BehaviorSubject<ApplicationCredential | null>(null);
  tokenSource$: Observable<ApplicationCredential | null> = this.tokenSource.asObservable();
  token: ApplicationCredential | null;

  constructor(
    private utilsService: UtilsService,
    private tokenAdapter: TokenAdapter,
    private companyRepository: CompanyRepository,
    private http: HttpClient
  ) {
    this.token = null;
  }

  resolve(): Observable<ApplicationCredential | null> {
    return new Observable(observer => {
      let token = this.localStorageToken();
      if (token) {
        const now = new Date(Date.now());
        const limitDate = new Date((token.expires_in * 1000) + token.created_at);
        const isExpired = (now > limitDate);
        if (isExpired) {
          token = this.getTokenByXHR();
        }
      } else {
        token = this.getTokenByXHR();
      }
      this.token = token;
      if (this.token) {
        this.save(this.token);
        this.tokenSource.next(this.token);
        observer.next(this.token);
        observer.complete();
      }
    });
  }

  save(token: ApplicationCredential): void {
    localStorage.setItem(this.TOKEN_APPLICATION, this.utilsService.encode(JSON.stringify(token)));
  }

  getToken(): ApplicationCredential | null {
    let token = this.localStorageToken();
    if (token) {
      const now = new Date(Date.now());
      const limitDate = new Date((token.expires_in * 1000) + token.created_at);
      const isExpired = (now > limitDate);
      if (isExpired) {
        token = this.getTokenByXHR();
      }
    }
    return token;
  }

  private localStorageToken(): ApplicationCredential | null {
    const rawData = localStorage.getItem(this.TOKEN_APPLICATION);
    let token = null;
    if (rawData) {
      token = this.tokenAdapter.validate(JSON.parse(this.utilsService.decode(rawData)));
    }
    return token;
  }

  private getTokenByXHR(): ApplicationCredential | null {
    const company = this.companyRepository.getCompany();
    if (company) {
      const params = `grant_type=client_credentials&scope=am_application_scope openid`;
      const xhr = new XMLHttpRequest();
      xhr.open('POST', settings.endpoint.url + this.API_TOKEN, false);
      xhr.setRequestHeader('Authorization', `Basic ${company.client}`)
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
      xhr.send(params);
      if (xhr.status === 200) {
        const resp = JSON.parse(xhr.responseText);
        const token = Object.assign(resp, { created_at: Date.now() });
        return this.tokenAdapter.validate(token);
      }
    } else {
      throw new Error('Error get company, token need company instance.');
    }
    return null;
  }

  public remove() {
    localStorage.removeItem(this.TOKEN_APPLICATION);
  }
}
