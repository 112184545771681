import { Endereco } from './../model/endereco';
import { Injectable } from "@angular/core";
import { CountryService } from "../services/country.service";
import { Observable } from "rxjs";
import { Country } from "src/app/core/models/country";


@Injectable()
export class CountryRepository {

  countries: Country[] = [];

  constructor(private countryService: CountryService) { }

  getList(): Observable<Country[]> {
    return this.countryService.getCountry();
  }
}
