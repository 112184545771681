import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { settings } from './settings';

enableProdMode();

function loadInfo(): void {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', `./info.json?nocache=${Date.now()}`, false);
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      const resp = JSON.parse(xhr.responseText);
      if (resp && (resp.endpoint || resp.versao || resp.contentManager || resp.ambiente)) {
        settings.type = resp.ambiente;
        Object.assign(settings, resp);
      }
    }
  }
  xhr.send();
}

if (window.location.hostname !== 'localhost') {
  loadInfo();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
