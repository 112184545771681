import { Injectable } from "@angular/core";
import { Company } from "../models/company";
import { UtilsService } from "src/app/shared/services/utils.service";
import { settings } from "src/settings";

@Injectable({
  providedIn: 'root'
})
export class CompanyAdapter {

  constructor(
    private utilsService: UtilsService
  ) { }

  public adapter(raw: any): Company {
    const company: Company = {
      id: raw['sgPessoaSiaf'] || null,
      name: raw['nome'] || null,
      client: raw['client'] || this.temporaryKey(),
      sortName: raw['nomeSocial'] || null,
      theme: raw['tema'] || null,
      logoImageUrl: this.utilsService.imageUrlToString(raw['logo']) || '',
      logoThemeImageUrl: this.utilsService.imageUrlToString(raw['imagemTema']) || ''
    };
    return this.validate(company);
  }

  public validate(company: Company): Company {
    if (!company.id || !company.name || !company.client || !company.sortName) {
      throw new Error('Error creating company, all attributes must be filled in.');
    }
    return company;
  }

  private temporaryKey() {
    let key = '';
    switch (settings.type) {
      case 'DEV':
        key = 'U2lhSWdRSzRZNU1QZl9xYjhVaE0xaTlSY3dvYTpROWtTU1k5WWdERGwzWVBxTWw3Q1MxUU44SGth';
        break;
      case 'HML':
        key = 'ZzVJcWVrUWxIc3BGZUkwMXdCdVRIUFlWU1JrYTpvOWhiUGhEQm9BU1FCa2pFY0FPZDUzeTkyc1Vh';
        break;
      default:
        key = 'U2lhSWdRSzRZNU1QZl9xYjhVaE0xaTlSY3dvYTpROWtTU1k5WWdERGwzWVBxTWw3Q1MxUU44SGth';
        break;
    }
    return key;
  }
}
