import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Company } from 'src/app/core/models/company';
import { CompanyService } from 'src/app/core/services/company.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { FilterAdapter } from '../adapters/filters.adapter';
import { Filters } from '../models/filters';
import { DomainAdapter } from '../adapters/domain.adapter';
import { Domain } from '../models/domain';

@Injectable()
export class ShopCategoriesService {

  private company: Company | null;
  private readonly DATA_URL = '/comumLoja/lstDado';
  private readonly UTIL_URL = '/comumLoja/getDominio'

  constructor(
    private oHttpClient: HttpClient,
    private oUtilsService: UtilsService,
    private oDomainAdapter: DomainAdapter,
    private oFilterAdapter: FilterAdapter,
    private oCompanyService: CompanyService,
  ) {
    this.company = this.oCompanyService.company;
  }

  getFiltersListByRequest(type?: string): Observable<Filters[]> {
    const oParams = {} as any;

    oParams['sgPessoaSiaf'] = this.company?.id;
    oParams['sgTpDado'] = type ? type : 'TAG_CLASSIFICADOR';
    oParams['flAtivo'] = 'S';

    return this.oHttpClient.get<any>(this.DATA_URL, { headers: this.oUtilsService.requestPublic(), params: oParams }).pipe(map(item => {
      if (item.items && item.items.length) {
        const list = item.items.filter((data: any) => data.items?.length);

        return list.map((filter: any) => this.oFilterAdapter.adapter(filter));
      }
      return [];
    }));
  }

  getOrderListByRequest(): Observable<Domain[]> {
    const oParams = {} as any;

    oParams['noDominio'] = 'TP_LOJA_ORDENACAO';

    return this.oHttpClient.get<any>(this.UTIL_URL, { headers: this.oUtilsService.requestPublic(true), params: oParams }).pipe(map(item => {
      if (item && item.length) {
        return item.map((data: any) => this.oDomainAdapter.adapter(data));
      }
      return [];
    }));
  }
}
