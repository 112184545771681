<ngx-spinner [bdColor]="spinnerConfig.bdColor" [color]="spinnerConfig.color" [type]="spinnerConfig.type"
  [fullScreen]="spinnerConfig.fullScreen">
</ngx-spinner>
<!-- <div class="mobile-menu d-md-none d-block mobile-cart">
  <ul>
    <li class="active">
      <a [routerLink]="['/']" routerLinkActive="router-link-active" class="cursor-pointer">
        <i class="iconly-Home icli"></i>
        <span>Home</span>
      </a>
    </li>

    <li class="active">
      <a [routerLink]="['/shop-by-categories/list']" routerLinkActive="router-link-active" class="cursor-pointer">
        <i class="iconly-Category icli js-link"></i>
        <span>Category</span>
      </a>
    </li>


    <li>
      <a href="search.html" class="search-box">
        <i class="iconly-Search icli"></i>
        <span>Search</span>
      </a>
    </li>

    <li class="cart-item">
      <a (click)="toggleCartOptions()">
        <i class="iconly-Bag-2 icli fly-cate"></i>
        <span>Cart</span>
      </a>
      <div style="display: flex;" class="onhover-div onhover-div-login" *ngIf="showCartOptions">
        <div class="onhover-div onhover-div-login">
          <ul class="user-box-name">
            <li class="product-box-contain">
              <i></i>
              <a [routerLink]="['/login']" routerLinkActive="router-link-active">{{ 'header.log_in' |
                translate }}</a>
            </li>
            <li class="product-box-contain">
              <a [routerLink]="['/sign-up']">{{ 'header.register' | translate }}</a>
            </li>
            <li class="product-box-contain">
              <a [routerLink]="['/forgot-password']" routerLinkActive="router-link-active">{{
                'header.forgot_password' | translate }}</a>
            </li>
          </ul>
          <ul class="user-box-name">
            <li class="product-box-contain">
              <i></i>
              <a [routerLink]="['/account']" routerLinkActive="router-link-active">{{ 'header.profile' |
                translate }}</a>
            </li>
            <li class="product-box-contain">
              <i></i>
              <a class="cursor-pointer" >{{ 'header.logout' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>
    </li>
    
  </ul>
</div> -->
<router-outlet></router-outlet>