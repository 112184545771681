import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MaintenancePageComponent } from './core/components/maintenance-page/maintenance-page.component';
import { CompanyResolver } from './core/resolvers/company.resolver';
import { TokenResolver } from './core/resolvers/token.resolver';
import { settings } from 'src/settings';

const routes: Routes = [
  {
    path: '',
    resolve: {
      company: CompanyResolver,
      tokenApplication: TokenResolver
    },
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'maintenance',
    component: MaintenancePageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: settings.type === 'DEV',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
