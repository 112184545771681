import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { UserRepository } from 'src/app/core/repository/user.repository';
import { environment } from 'src/environments/environment';

// tslint:disable-next-line:naming-convention
export interface Settings {
  endpoint?: {
    identityKey: string;
    url: string;
    tokenUrl?: string;
  };
  contentManager?: {
    rootFolder: string
  };
  versao?: string;
  data?: string;
  ambiente?: string;
  logomenu?: boolean;
  help?: {
    url: string;
    key: string;
  },
  context?: string;
  subcontexts?: string[];
  mobile?: boolean;
}

interface IFicheiroCache {
  contentId: string;
  fields: any;
}

export interface IFile {
  name: string;
  type: string;
  size: number;
  isError: boolean;
  isSuccess: boolean;
  isUploading: boolean;
  index: number;
  contentId?: string;
  bKnownType?: boolean;
  disabled: boolean;
  required: boolean;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FicheiroService {

  user: User | null;
  private sUrl = '/alfresco';
  private blobCache = new Array<IFicheiroCache>();

  constructor(
    private http: HttpClient,
    private oUserRepository: UserRepository,
  ) {
    this.user = this.oUserRepository.getUser();
   }

     /**
   * Verifica se  arquivo existe pelo nome
   * @param sFileName Nome do arquivo
  */
  getFileByName(sFileName: string, sFolderNodeId: string) {
    const uParams = {} as any;
    uParams['term'] = sFileName;
    uParams['rootNodeId'] = sFolderNodeId;
    uParams['nodeType'] = 'cm:content';
    return this.http.get(`${this.sUrl}/queries/nodes`, { params: uParams }).pipe(map(item => {
      return item
    }));
  }

    /**
   * Retorna o content do arquivo especifico para download
   * @param sFileId O identificador do arquivo no repositório
   */
    getContentFromFile(sFileId: string) {
      return Observable.create((observer: any) => {
        this.getFieldsFromFile(sFileId)
          .subscribe((fields) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${environment.endpoint.url}${this.sUrl}/nodes/${sFileId}/content`, true);
            xhr.setRequestHeader('Authorization', `${this.user?.credential?.token_type} ${this.user?.credential?.access_token}`);
            xhr.responseType = 'blob';
            xhr.onreadystatechange = function () {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  const contentType = fields.entry.content.mimeType;
                  const blob = new Blob([xhr.response], { type: contentType });
                  observer.next({ blob: blob, name: fields.entry.name });
                  observer.complete();
                } else {
                  observer.error(xhr.response);
                }
              }
            }
            xhr.send();
          });
      }
      )
    }

    /**
   * Retorna o content especifico em blob
   * @param sFileId O identificador do conteudo no repositório
  */
    getContent(sFileId: string, bSilent?: boolean, bShowContentId: boolean = false): Observable<any> {
      if (this.getCacheResponse(sFileId, 'blob')) {
        const oBlob = this.getCacheResponse(sFileId, 'blob');
        return of( bShowContentId ? {file: oBlob, contentId: sFileId} : oBlob);
      }
      const that = this;
      return Observable.create((observer: any) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${environment.endpoint.url}${this.sUrl}/nodes/${sFileId}/content`, true);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              const blob = new Blob([xhr.response]);
              that.saveCacheResponse.call(that, sFileId, 'blob', blob);
              observer.next(bShowContentId ? {file: blob, contentId: sFileId} : blob);
              observer.complete();
            } else {
              observer.error(xhr.response);
            }
          }
        }
        xhr.send();
      })
    }

  /**
 * Retorna as informações básicas do arquivo
 * @param sFileId O identificador do arquivo no repositório
*/
  getFieldsFromFile(sFileId: string) {
    if (this.getCacheResponse(sFileId, 'entry')) {
      return of(this.getCacheResponse(sFileId, 'entry'))
    }
    return this.http.get(`${this.sUrl}/nodes/${sFileId}`).pipe(
      map((res: any) => Object.assign(res.json(), { contentId: sFileId })),
      tap((res: any) => this.saveCacheResponse(sFileId, 'entry', res))
    );
  }

  private getCacheResponse(contentId: string, field: string) {
    const cache = this.blobCache.find(item => item.contentId === contentId);
    if (cache && cache.fields.hasOwnProperty(field)) {
      return cache.fields[field];
    }
    return null;
  }

  private saveCacheResponse(contentId: string, field: string, value: any) {
    let index = this.blobCache.findIndex(item => item.contentId === contentId);
    index = index === -1 ? this.blobCache.push({ contentId: contentId, fields: {} }) - 1 : index;
    this.blobCache[index].fields = Object
      .defineProperty(this.blobCache[index].fields,
        field,
        { writable: true, configurable: true, enumerable: true });
    this.blobCache[index].fields[field] = value;
  }
}
