import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CategoryAdapter } from './adapters/category.adapter';
import { CheckoutAdapter } from './adapters/checkout.adapter';
import { FiscalDocumentAdapter } from './adapters/doc-fiscal.adapter';
import { DomainAdapter } from './adapters/domain.adapter';
import { FilterAdapter } from './adapters/filters.adapter';
import { OrderSuccessAdapter } from './adapters/order.adapter';
import { ProductAdapter } from './adapters/product.adapter';
import { CategoryService } from './services/category.service';
import { CheckoutService } from './services/checkout.service';
import { OrderService } from './services/order.service';
import { ProductsService } from './services/products.service';
import { ShopCategoriesService } from './services/shop-categories.service';
import { PaymentQrCodeAdapter } from './adapters/qr-code.adapter';
import { AccountService } from '../home/components/services/account.service';
import { UserContactsAdapter } from '../core/adapters/user-contacts.adapter';
import { FeaturedProductsAdapter } from './adapters/featured-products.adapters';
import { ViewCartAdapter } from './adapters/view-cart.adapter';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CategoryService,
    ProductsService,
    CheckoutService,
    OrderService,
    ShopCategoriesService,
    AccountService,
    CategoryAdapter,
    ProductAdapter,
    CheckoutAdapter,
    OrderSuccessAdapter,
    FiscalDocumentAdapter,
    PaymentQrCodeAdapter,
    FilterAdapter,
    DomainAdapter,
    UserContactsAdapter,
    FeaturedProductsAdapter,
    ViewCartAdapter
  ]
})
export class ShoppingModule { }
