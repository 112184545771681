import { Injectable } from "@angular/core";
import { SlugfyService } from "src/app/shared/services/slugfy.service";
import { UtilsService } from "src/app/shared/services/utils.service";
import { Category } from "../models/category";

@Injectable()
export class CategoryAdapter {

  constructor(
    private utilsService: UtilsService,
    private slugfyService: SlugfyService
  ) { }

  adapter(raw: any): Category {
    const category: Category = {
      id: raw['id'] || null,
      name: raw['nome'] || null,
      nameLink: this.slugfyService.transform(raw['nome']),
      imageUrl: null,
      active: raw['ativo'] || '',
      type: raw['sgDado'] || '',
      order: raw['nuOrdem'] || '',
      quantity: raw['qtdRegistros'] || 0
    };

    // add image
    if (raw['anexos'] && raw['anexos'].length) {
      category.imageUrl = this.utilsService.imageUrl(raw.anexos[0].contentId);
    }

    return this.validate(category);
  }

  private validate(category: Category): Category {
    if (!category.id || !category.name || !category.nameLink || category.active === null) {
      throw new Error('Error creating category, all attributes must be filled in.');
    }
    return category;
  }

}
