import { environment } from "./environments/environment";

const defaultSettings: any = {
  endpoint: {
    identityKey: 'SHhORUtERGMxQVJpWUpJTEpnZ0hvUE4ydEM4YTpLMjJRT2l5M2FWV0ZRcHh3a3BaMHg3SlRvckVh',
    url: 'https://www22.siaf.co.ao'
  },
  contentManager: {
    rootFolder: '30e823f6-d58e-489b-b87e-8bb84d025152'
  },
  type: 'DEV',
  privacyPoliceUrl: 'https://dev.corpore.co.ao/terms-conditions',
  contentManagerUrl: '/alfresco/nodes/#IMAGE_ID#/content/',
  contentManagerRootUrl: '/alfresco',
  scopes: 'openid '
};

export const settings: any = Object.assign(defaultSettings, environment);