import { Injectable } from "@angular/core";
import { SlugfyService } from "src/app/shared/services/slugfy.service";
import { UtilsService } from "src/app/shared/services/utils.service";
import { CartProduct } from "../models/cart-produt";

@Injectable()
export class ViewCartAdapter {

  constructor(private oUtilsService: UtilsService) { }

  adapter(raw: any): CartProduct {
    const product: any = {
      idViewCart: raw['idLojaCarrinho'],
      id: raw['idClassificador'],
      name: raw['noClassificador'],
      quantity: raw['nuQuantidade'],
      value: raw['vlProduto'],
      total: raw['vlTotalProduto'],
      quantityStock: raw['nuQtdEstoque'],
      imageUrl: null
    };

    if (raw['contentId']) {
      product.imageUrl = this.oUtilsService.imageUrl(raw.contentId);
    }

    return this.validate(product);
  }

  public validate(product: CartProduct): CartProduct {
    if (!product.id || !product.name || !product.value) {
      throw new Error('Error creating product, all attributes must be filled in.');
    }
    return product;
  }
}