import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';
import { User } from 'src/app/core/models/user';
import { UserRepository } from 'src/app/core/repository/user.repository';
import { CartProduct } from 'src/app/shopping/models/cart-produt';
import { Product } from 'src/app/shopping/models/product';
import { CheckoutService } from 'src/app/shopping/services/checkout.service';
import Swal from 'sweetalert2';
import { UrlService } from '../../services/url.service';
import { ViewCartService } from '../../services/view-cart.service';
import { SalesAdressPartner } from './../../../shopping/models/taxes';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-cart',
  templateUrl: './view-cart.component.html',
  styleUrls: ['./view-cart.component.scss']
})
export class ViewCartComponent implements OnInit {

  oForm!: FormGroup;
  user: User | null;
  aCartProducts: Array<CartProduct> | null = new Array<CartProduct>();
  limit: number | any;
  minValueAccept: number;

  constructor(
    private oRouter: Router,
    private oUrlService: UrlService,
    private oFormBuilder: FormBuilder,
    private oUserRepository: UserRepository,
    private oViewCartService: ViewCartService,
    private oCheckoutService: CheckoutService,
    private oTranslateService: TranslateService,
    private spinner: NgxSpinnerService,
  ) {
    this.oForm = this.oFormBuilder.group({
      products: this.oFormBuilder.array([])
    });
    this.user = this.oUserRepository.getUser();

    this.getAdressPartner().subscribe();
  }

  ngOnInit() {
    this.getProdutosCarrinho().subscribe(() => {
      if (this.aCartProducts) {
        for (let index = 0; index < this.aCartProducts.length; index++) {
          const item = this.aCartProducts[index] as CartProduct;
          this.products.push(this.createItem(item));
        }
      }
    });
  }

  getAdressPartner(): Observable<any> {
    return new Observable<any>((oObserver: Subscriber<any>) => {
      this.oCheckoutService.getAdressPartner().subscribe((oRet: SalesAdressPartner) => {
        if (oRet) {
          this.minValueAccept = oRet.valorMinimoPedido;
          oObserver.next();
          oObserver.complete();
        }
      });
    });
  }

  onLoad() {
    if (!this.minValueAccept || (this.getTotalValueProducts() >= this.minValueAccept)) {
      if (this.user) {
        let aProducts: Array<any> = [];

        for (let i = 0; i < this.products.controls.length; i++) {
          const product = this.products.controls[i] as any;

          aProducts.push({ idLojaCarrinho: product.value.idViewCart, nuQuantidade: product.controls['quantityViewCart'].value });
        }

        this.oViewCartService.updateCart(aProducts).subscribe((res) => {
          this.oViewCartService.cartSource.next(res);
        });
        this.oRouter.navigate(['/checkout']);
      } else {
        this.oUrlService.setUrlRoute(this.oRouter.url);
        this.oRouter.navigate(['/login']);
      }
    } else {
      const value = this.minValueAccept?.toLocaleString('pt-AO', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.oTranslateService.instant('common.warning'),
        text: this.oTranslateService.instant('products.message.minimum_order_without_adding_fees_taxes', { value: value }),
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
  }

  getTotalValueProducts(): number {
    if (this.aCartProducts) {
      let totalValue = 0;

      for (let index = 0; index < this.products?.controls.length; index++) {
        const product = this.products?.controls[index];
        totalValue = (totalValue + (product.get('value')?.value * product.get('quantityViewCart')?.value));
      }
      return totalValue
    }
    return 0;
  }

  getSumLineProduct(valueProduct: any, qtdProduct: any) {
    return valueProduct * qtdProduct;
  }

  onChangeCount(index: number, param: string) {
    const cart = this.products.controls[index].get('quantityViewCart')?.value;
    const quantity = this.products.controls[index].get('quantityStock')?.value;

    switch (param) {
      case 'MORE':
        if (cart < quantity) {
          this.products?.controls[index].get('quantityViewCart')?.setValue(cart + 1);
          this.products?.controls[index].get('quantity')?.setValue(quantity + 1);
        }
        break;
      case 'LESS':
        if (this.products?.controls[index].get('quantityViewCart')?.value > 1) {
          this.products?.controls[index].get('quantityViewCart')?.setValue(cart - 1);
          this.products?.controls[index].get('quantity')?.setValue(quantity - 1);
        }
        break;
    }
  }

  onRemove(idViewCart: number, idProduct: number) {
    if (this.aCartProducts) {
      let index: number;

      if (idViewCart) {
        index = this.aCartProducts.findIndex((product: CartProduct) => product.idViewCart === idViewCart);
      } else {
        index = this.aCartProducts.findIndex((product: CartProduct) => product.id === idProduct);
      }

      if (index >= 0) {
        this.aCartProducts.splice(index, 1);
        this.products.removeAt(index);

        if (idViewCart) {
          this.spinner.show();
          this.oViewCartService.deleteCart(idViewCart).subscribe(() => {
            this.oViewCartService.cartSource.next(this.aCartProducts);
            this.spinner.hide();
          });
        } else {
          this.oViewCartService.delCartStorage(idProduct);
          this.oViewCartService.cartSource.next(this.aCartProducts);
        }
      }
    }
  }

  getImageUrl(id: number): SafeResourceUrl | null {
    if (this.aCartProducts) {
      const item = this.aCartProducts.find((prod: Product) => prod.id === id);
      return (item && item.imageUrl) ? item.imageUrl : null;
    }
    return null;
  }

  private getProdutosCarrinho() {
    return new Observable<any>((oObserver: Subscriber<any>) => {
      if (this.user) {
        this.oViewCartService.getCart().subscribe((res: any) => {
          this.aCartProducts = res.produtos;
          oObserver.next(this.aCartProducts);
          oObserver.complete();
        });
      } else {
        this.aCartProducts = this.oViewCartService.getCartStorage();
        oObserver.next(this.aCartProducts);
        oObserver.complete();
      }
    });
  }

  private createItem(item: CartProduct): FormGroup {
    const oFormGroup = this.oFormBuilder.group({
      id: [item.id, []],
      idViewCart: [item.idViewCart, []],
      name: [item.name, []],
      imageUrl: [item.imageUrl, []],
      value: [item.value, []],
      quantityStock: [!this.user ? item.quantity : item.quantityStock, []],
      quantityViewCart: [item.quantityViewCart ? item.quantityViewCart : item.quantity, []],
      quantity: [item.quantity, []],
    });

    oFormGroup.get('quantityViewCart')?.disable();

    return oFormGroup;
  }

  qtdLimit(index: number, quantity: any) {
    const qtdStock = this.products.controls[index].get('quantityViewCart');

    if (quantity.value <= qtdStock?.value) {
      qtdStock?.setValue(quantity.value);
    }
    if (!qtdStock?.value || qtdStock?.value === 0) {
      qtdStock?.setValue(1);
    }
  }

  get products(): FormArray {
    return this.oForm.get('products') as FormArray;
  }
}
