import { Injectable } from "@angular/core";
import { ProductAdapter } from "./product.adapter";
import { FeaturedProducts } from "../models/featured-products";

@Injectable()
export class FeaturedProductsAdapter {

  constructor(
    private oProductAdapter: ProductAdapter
  ) { }

  adapter(raw: any): FeaturedProducts {
    const section: FeaturedProducts = {
      name: raw['noSecao'] || null,
      type: raw['sgSecao'] || null,
      products: []
    };

    if (raw['classificadores']?.length) {
      for (let index = 0; index < raw['classificadores'].length; index++) {
        const product = raw['classificadores'][index];
        section.products.push(this.oProductAdapter.adapter(product));
      }
    }
    return this.validate(section);
  }

  public validate(section: FeaturedProducts): FeaturedProducts {
    if (!section.name || !section.type || !section.products) {
      throw new Error('Error creating section, all attributes must be filled in.');
    }
    return section;
  }

}
