<div class="slideshow-container" *ngIf="files && files.length > 0">
  <div class="steps-button">
    <button class="btn btn-md icon" type="button" (click)="onPagination(-1)">
      <i class="fa-solid fa-arrow-left"></i>
    </button>
    <button class="btn btn-md icon" type="button" (click)="onPagination(1)">
      <i class="fa-solid fa-arrow-right"></i>
    </button>
  </div>

  <div class="slides fade" *ngFor="let item of files; index as i">
    <div class="numbertext">{{getLabelIndexPage(i)}}</div>
    <img *ngIf="item.type; else frame;" class="image-slide blur-up lazyload" [src]="getUrlImage(item.contentId)" />

    <ng-template #frame>
      <div [id]="'slide-'+ i"></div>
    </ng-template>
  </div>
</div>
<br>