import { Injectable } from "@angular/core";
import { UtilsService } from "src/app/shared/services/utils.service";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../models/user";
import { UserAdapter } from "../adapters/user.adapter";
import { UserProfile } from "../models/user-profile";
import { UserProfileAdapter } from "../adapters/user.profile.adapter";
import { UserAddress } from "../models/user-address";
import { UserAddressAdapter } from "../adapters/user.address.adapter";
import { LoginService } from "../services/login.service";
import { UserCredential } from "../models/user-credential";
import { CredentialAdapter } from "../adapters/credential.adapter";

@Injectable({
  providedIn: 'root'
})
export class UserRepository {

  private readonly SESSION_KEY = 'SESSION';
  private readonly SESSION_TEMP_KEY = 'SESSION_TEMP';

  userSource = new BehaviorSubject<User | null>(this.getUser());
  userSource$: Observable<User | null> = this.userSource.asObservable();
  user: User | null;

  constructor(
    private userAdapter: UserAdapter,
    private userProfileAdapter: UserProfileAdapter,
    private credentialAdapter: CredentialAdapter,
    private userAddressAdapter: UserAddressAdapter,
    private utilsService: UtilsService
  ) {
    this.user = null;
  }

  create(raw: any): User {
    const user: User = this.userAdapter.validate(this.userAdapter.adapter(raw));
    this.userSource.next(user);
    return user;
  }

  createUserTemporaryCredential(raw: any): void {
    const credential = this.credentialAdapter.validate(this.credentialAdapter.adapter(raw));
    localStorage.setItem(this.SESSION_TEMP_KEY, this.utilsService.encode(JSON.stringify(credential)));
  }

  createUserProfile(raw: any): UserProfile {
    return this.userProfileAdapter.validate(this.userProfileAdapter.adapter(raw));
  }

  createUserAddress(raw: any): UserAddress {
    return this.userAddressAdapter.validate(this.userAddressAdapter.adapter(raw));
  }

  getUser(): User | null {
    const rawData = localStorage.getItem(this.SESSION_KEY);
    return (rawData) ? this.userAdapter.validate(JSON.parse(this.utilsService.decode(rawData))) : null;
  }

  save(user: User): void {
    this.userSource.next(user);
    localStorage.setItem(this.SESSION_KEY, this.utilsService.encode(JSON.stringify(user)));
  }

  remove(): void {
    this.userSource.next(null);
    localStorage.removeItem(this.SESSION_KEY);
  }

}
