import { Injectable } from "@angular/core";
import { Fare, SalesAdressPartner } from "../models/taxes";

@Injectable()
export class CheckoutAdapter {

  constructor() { }

  adapter(raw: any): Fare {
    const fare: Fare = {
      valueProducts: raw['vlTotalProdutos'] || 0,
      valueDelivery: raw['vlTotalEntrega'] || 0,
      valueService: raw['vlTotalServico'] || 0,
      valueTax: raw['vlTotalImpostos'] || 0,
      valueOrder: raw['vlMinPedido'] || 0,
      minTime: raw['nuPrazoPedido'] || 0,
      maxTime: raw['nuPrazoPedidoMax'] || 0,
      taxes: null,
      classificadores: null,
    };

    if (raw['taxas']?.length) {
      fare.taxes = raw['taxas'].map((item: any) => {
        return {
          id: item.idClassificador,
          value: item.vlUnitario,
          quantity: item.nuQuantidade,
          valueTaxe: item.vlTaxaCambio,
        }
      });
    }
    if (raw['classificadores']?.length) {
      fare.classificadores = raw['classificadores'].map((item: any) => {
        return {
          id: item.idClassificador,
          nuQuantidade: item.nuQuantidade,
          vlImposto: item.vlImposto,
          vlUnitario: item.vlUnitario,
          valueUnitaroFinal: item.vlUnitarioFinal,
          vlTaxaCambio: item.vlTaxaCambio
        }
      });
    }

    return this.validate(fare);
  }

  private validate(fare: Fare): Fare {
    if (!this.validateNumber(fare.valueProducts) || !this.validateNumber(fare.valueDelivery) || !this.validateNumber(fare.valueService) || !this.validateNumber(fare.valueOrder) || !this.validateNumber(fare.minTime) || !this.validateNumber(fare.maxTime)) {
      throw new Error('Error creating fare, all attributes must be filled in.');
    }
    return fare;
  }

  private validateNumber(item: number) {
    return (typeof item === 'number');
  }

  adapterAdressPartner(raw: any): SalesAdressPartner {
    const adressPartner: SalesAdressPartner = {
      noPessoa: raw['noPessoa'],
      valorMinimoPedido: raw['valorMinimoPedido']
    };

    if (raw['enderecos'].length) {
      adressPartner.enderecos= raw['enderecos'].map((item: any) => {
        return {
          idPesEndereco: item.idPesEndereco
        }
      });
    }
    return adressPartner;
  }
}
