import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public objSearch: BehaviorSubject<any> = new BehaviorSubject([]);
  public objRequest: BehaviorSubject<any> = new BehaviorSubject([]);

}
