import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { UrlInterceptor } from './interceptors/url.interceptor';

@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule,
    HttpClientModule,
    TranslateModule,
    RouterModule
  ],
  declarations: [
    MaintenancePageComponent
  ],
  exports: [
    MaintenancePageComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true }
  ]
})
export class CoreModule { }
