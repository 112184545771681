// error.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { ErrorService } from '../services/error.service';
import { UserRepository } from '../repository/user.repository';
import { TokenRepository } from '../repository/token.repository';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private loginService: LoginService,
    private errorService: ErrorService,
    private userRepository: UserRepository,
    private tokenRepository: TokenRepository
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Ignore the insPayment URL
    if (request.url.includes('/insPayment')) {
      return next.handle(request);
    }

    const mainRedirect = request.url.includes('/utilizador/buscaInfoPessoaSiaf');
    const tokenError = request.url.includes('/token');
    const accessError = request.url.includes('/acessos');
    const acountError = request.url.includes('/atualizaInfoPessoa');
    const checkoutError = request.url.includes('/vendasLoja/geraVenda');
    const checkoutErrorPayment = request.url.includes('/emis_gpo_points_of_sale');
    const checkoutAdressError = request.url.includes('/vendasLoja/getTotaisVenda');
    const checkoutErrorPaymentWebFrame = request.url.includes('/emis_gpo_webframe/getToken');

    return next.handle(request).pipe(
      catchError(error => this.handleError(error, mainRedirect, tokenError, accessError, acountError, checkoutError, checkoutAdressError, checkoutErrorPayment, checkoutErrorPaymentWebFrame))
    );
  }

  private handleError(error: HttpErrorResponse, mainRedirect: boolean, tokenError: boolean, accessError: boolean, checkoutAdressError: boolean, acountError: boolean, checkoutError: boolean, checkoutErrorPayment: boolean, checkoutErrorPaymentWebFrame: boolean): Observable<HttpEvent<any>> {
    const CheckoutPayment = '/emis_gpo_webframe/';
    if (error.status === 404 && mainRedirect) {
      this.router.navigateByUrl('/maintenance');
    } else if (error.status === 400 && checkoutErrorPaymentWebFrame) {
      this.errorService.showCheckoutErrorFrame(error);
    } else if (error.status === 400 && error.url?.indexOf(CheckoutPayment) !== -1) {
      localStorage.setItem('checkoutErrorPaymentMessage', error.message); // Armazene a mensagem de erro no localStorage
    } else if (error.status === 404 && accessError) {
      return throwError(error.message);
    } else if (error.status === 401) {
      if (this.userRepository.getUser()) {
        this.loginService.logout();
      }
    } else if (error.status === 400 && checkoutError) {
      localStorage.setItem('error', error.error.message)
    } else if (error.status === 400 && checkoutAdressError) {
      this.errorService.showCheckoutError(error);
    } else if (error.status === 400 && tokenError) {
      this.loginService.showErrorLogin();
    } else if (error.status === 500 && acountError) {
      this.errorService.showAgeErrorAdmin(error);
    } else if (error.status === 400 && acountError) {
      this.errorService.showAgeError(error);
    } else if (error.status === 409) {
      this.errorService.showCommonErrorUser(error);
    } else {
      this.errorService.showCommonError(error);
    }
    return throwError(error.message);
  }
}
