import { Injectable } from "@angular/core";
import { Fare } from "../models/taxes";
import { Order } from "../models/order";

@Injectable()
export class OrderSuccessAdapter {

  constructor() { }

  adapter(raw: any): Order {
    const order: Order = {
      id: raw['nuContrato'] || null,
      name: raw['noPessoa'] || null,
      date: raw['prazo'] || null,
      reference: raw['nuReferencia'] || null,
      entity: raw['nuEntidade'] || null,
      value: raw['vlContrato'] || null,
      orderHistory: null,
    };

    if (raw['historico'].length) {
      order.orderHistory = raw['historico'].map((item: any) => {
        return {
          date: item.dtOperacao,
          status: item.stContrato,
          type: item.sgDado,
          visible: item.flVisivel === 'S' ? true : false
        }
      });
    }

    return this.validate(order);
  }

  private validate(order: Order): Order {
    if (!order.id || !order.name || !order.date || !order.value || !order.orderHistory) {
      throw new Error('Error creating sale, all attributes must be filled in.');
    }
    return order;
  }

}
