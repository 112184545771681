import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CountryAdapter } from '../adapters/country.adapter';
import { Country } from 'src/app/core/models/country';
import { UtilsService } from './utils.service';

@Injectable()
export class CountryService {

  private readonly COUNTRY_URL = '/siaf-localidade/lstPais';
  private readonly LOCALIDADE_URL = '/pessoaLoja/lstLocalidade';

  constructor(
    private http: HttpClient,
    private countryAdapter: CountryAdapter,
    private utilsService: UtilsService
  ) { }

  getCountry(): Observable<Country[]> {
    return this.http.get<any>(this.COUNTRY_URL, { headers: this.utilsService.requestPublic() }).pipe(map(item => {
      if (item.lines && item.lines.length) {
        return item.lines.map((country: any) => this.countryAdapter.adapter(country));
      }
      return [];
    }));
  }
}
