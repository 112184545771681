import { Injectable } from "@angular/core";
import { Company } from "../models/company";
import { UtilsService } from "src/app/shared/services/utils.service";
import { HttpClient } from "@angular/common/http";
import { CompanyAdapter } from "../adapters/company.adapter";
import { BehaviorSubject, Observable } from "rxjs";
import { settings } from "src/settings";

@Injectable({
  providedIn: 'root'
})
export class CompanyRepository {

  private readonly API = '/utilizador';
  private readonly COMPANY_LOCAL_URL = 'https://corpore.co.ao';
  private readonly COMPANY_KEY = 'COMPANY';
  companySource = new BehaviorSubject<Company | null>(null);
  companySource$: Observable<Company | null> = this.companySource.asObservable();
  company: Company | null;

  constructor(
    private http: HttpClient,
    private companyAdapter: CompanyAdapter,
    private utilsService: UtilsService
  ) {
    this.company = null;
  }

  resolve(): Observable<Company | null> {
    return new Observable(observer => {
      const rawData = localStorage.getItem(this.COMPANY_KEY);
      if (rawData) {
        this.company = this.companyAdapter.validate(JSON.parse(this.utilsService.decode(rawData)));
        this.companySource.next(this.company);
        observer.next(this.company);
        observer.complete();
      } else {
        this.company = this.getCompanyByXHR();
        if (this.company) {
          this.save(this.company);
          this.companySource.next(this.company);
          observer.next(this.company);
          observer.complete();
        } else {
          observer.next(null);
          observer.complete();
        }
      }
    });
  }

  private getCompanyByXHR(): Company | null {
    const params = `grant_type=client_credentials&scope=am_application_scope openid`;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${settings.endpoint.url}${this.API}/buscaInfoPessoaSiaf?url=${this.url()}`, false);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    xhr.send(params);
    if (xhr.status === 200) {
      const resp = JSON.parse(xhr.responseText);
      localStorage.setItem('sgPessoaSiaf', resp.sgPessoaSiaf);
      localStorage.setItem('imagemTema', resp.imagemTema);
      return this.companyAdapter.validate(this.companyAdapter.adapter(resp));
    }
    return null;
  }

  getCompany(): Company | null {
    const rawData = localStorage.getItem(this.COMPANY_KEY);
    return (rawData) ? this.companyAdapter.validate(JSON.parse(this.utilsService.decode(rawData))) : null;
  }

  save(company: Company): void {
    localStorage.setItem(this.COMPANY_KEY, this.utilsService.encode(JSON.stringify(company)));
  }

  remove(): void {
    localStorage.removeItem(this.COMPANY_KEY);
  }

  public url(): string {
    return (window.location.origin.includes('localhost')) ? this.COMPANY_LOCAL_URL : window.location.origin;
  }
}
