import { Injectable } from "@angular/core";
import { Filters } from "../models/filters";

@Injectable()
export class FilterAdapter {

  constructor() { }

  adapter(raw: any): Filters {
    const filter: Filters = {
      id: raw['id'] || null,
      name: raw['nome'] || null,
      active: raw['ativo'] || '',
      type: raw['sgDado'] || '',
      order: raw['nuOrdem'] || '',
      quantity: raw['qtdRegistros'] || 0,
      items: null,
      visible: raw['dadoAtributo'] && raw['dadoAtributo'].flMostraTelaDado === 'S' ? true : false
    };

    if (raw['items']?.length) {
      filter.items = raw['items'].map((item: any) => {
        return {
          type: item.sgDado,
          name: item.nome,
          quantity: item.qtdRegistros,
          visible: raw['dadoAtributo'] && raw['dadoAtributo'].flMostraTelaDado === 'S' ? true : false
        }
      });
    }

    return this.validate(filter);
  }

  private validate(filter: Filters): Filters {
    if (!filter.id || !filter.name || filter.active === null) {
      throw new Error('Error creating filter, all attributes must be filled in.');
    }
    return filter;
  }

}
