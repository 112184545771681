<!-- 404 Section Start -->
<section class="section-404 section-lg-space">
  <div class="container-fluid-lg">
      <div class="row">
          <div class="col-12">
              <div class="image-404">
                  <img src="./assets/images/health-problem.gif" style="max-height: 400px;max-width: 200px;" class="img-fluid blur-up lazyload" alt="">
              </div>
          </div>

          <div class="col-12">
              <div class="contain-404">
                <h2 class="mb-4">{{ 'maintenance.the_website' | translate }}</h2>
                  <h3 class="text-content">{{ 'maintenance.we_apologize' | translate }}</h3>
                  <button type="button" onclick="location.href = '/';" class="btn btn-md text-white theme-bg-color mt-4 mx-auto">{{ 'maintenance.back_to' | translate }}</button>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- 404 Section End -->