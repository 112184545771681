import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Category } from 'src/app/shopping/models/category';
import { CategoryService } from 'src/app/shopping/services/category.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AccountService } from 'src/app/home/components/services/account.service';
import { UtilsService } from '../../services/utils.service';
import { SystemStoreService } from '../../services/sistema-loja';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  data: any;
  facebook: any;
  instagram: any;
  addressDescription: any;
  linkedin: any;
  titleTrade: string;
  deliveryLocation: string;
  deliveryLocationDescription: string;
  freight: string;
  freightDescription: string;
  securePayment: string;
  securePaymentDescription: string;
  talkToUs: string;
  talkToUsDescription: string;
  titleAboutUs: string;
  appleStore: string;
  playStore: string;
  titleContacts: string;
  phoneConact: string;
  phoneConactDescription: string;
  titleTermsConditions: string;
  titleMessagePromotional: string;
  textPromotional: string;
  imageLogo: any;

  @Input() companyName = '';

  @Input() companyImageUrl: SafeResourceUrl = '';

  categories: Category[] = [];

  constructor(
    private http: HttpClient,
    private oAccountService: AccountService,
    private utilsService: UtilsService,
    private oSystemStoreService: SystemStoreService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('imagemTema')) {
      this.imageLogo = localStorage.getItem('imagemTema');
    }
    this.oSystemStoreService
      .getparametroSistemaLojaListByRequest()
      .subscribe((datas) => {
        for (let i = 0; i < datas.lines.length; i++) {
          const item = datas.lines[i];
          switch (item.sgTpParametroSistema) {
            case 'DS_LINK_FACEBOOK':
              this.facebook = item.vlParametroSistema;
              break;
            case 'DS_LINK_INSTAGRAM':
              this.instagram = item.vlParametroSistema;
              break;
            case 'DS_LINK_LINKEDIN':
              this.linkedin = item.vlParametroSistema;
              break;
            case 'DS_TROCAS_DEVOLUCOES':
              this.titleTrade = item.noTpParametroSistema;
              break;
            case 'DS_TERMOS_CONDICOES':
              this.titleTermsConditions = item.noTpParametroSistema;
              break;
            case 'DS_TEXTO_PROMOCIONAL':
              this.textPromotional = item.vlParametroSistema;
              break;
            case 'DS_SOBRE_NOS':
              this.titleAboutUs = item.noTpParametroSistema;
              break;
            case 'DS_CONTACTE_NOS':
              this.titleContacts = item.noTpParametroSistema;
              break;
            case 'DS_TELEFONE':
              this.phoneConact = item.noTpParametroSistema;
              this.phoneConactDescription = item.vlParametroSistema;
              break;
            case 'DS_ENDERECO':
              this.addressDescription = item.vlParametroSistema;
              break;
            case 'DS_LOCAL_ENTREGA':
              this.deliveryLocation = item.noTpParametroSistema;
              this.deliveryLocationDescription = item.vlParametroSistema;
              break;
            case 'DS_FRETE':
              this.freight = item.noTpParametroSistema;
              this.freightDescription = item.vlParametroSistema;
              break;
            case 'DS_PAGAMENTO':
              this.securePayment = item.noTpParametroSistema;
              this.securePaymentDescription = item.vlParametroSistema;
              break;
            case 'DS_FALE_CONOSCO':
              this.talkToUs = item.noTpParametroSistema;
              this.talkToUsDescription = item.vlParametroSistema;
              break;
            case 'DS_LINK_APPLESTORE':
              this.appleStore = item.vlParametroSistema;
              break;
            case 'DS_LINK_PLAYSTORE':
              this.playStore = item.vlParametroSistema;
              break;
            case 'DS_MENSAGEM_PROMOCIONAL':
              this.titleMessagePromotional = item.noTpParametroSistema;
              localStorage.setItem(
                'DS_MENSAGEM_PROMOCIONAL',
                item.vlParametroSistema
              );
              break;
            default:
              break;
          }
        }
      });
  }

  carregarProduto(sValue?: string) {
    return this.oAccountService.renderImage(sValue);
  }
}
