import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  private readonly COOKIE_KEY = 'COOKIES';

  constructor() { }

  acceptCookie(): void {
    localStorage.setItem(this.COOKIE_KEY, 'true');
  }

  removeCookie(): void {
    localStorage.removeItem(this.COOKIE_KEY);
  }

  isAcceptedCookie(): boolean {
    const cookie = localStorage.getItem(this.COOKIE_KEY);
    return (cookie === 'true');
  }

}
