import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

export class LocaleIdWrapper extends String {
  constructor(private service: LocaleService) {
    super('');
  }
  // toString(): string | null {
  //   return this.service.getLocaleSelected();
  // }
}

/**
 * Servico para encapsulamento do codigo relacionado a Internacionalizacao.
 *
 * @author Rafael Veloso
 */
@Injectable()
export class LocaleService {

  public static availableLangs = [{
    name: 'shared.portugues',
    code: 'pt'
  }, {
    name: 'shared.ingles',
    code: 'en'
  }];

  private KEY_LOCAL_LANG = 'locale';

  private DEFAULT_LANG = LocaleService.availableLangs[0].code;

  /**
   * @param translate Servico para utilizacao do componente de Internacionalizacao.
   */
  constructor(private translate: TranslateService) { }

  /**
   * Inicializa as configuracoes do localidade com o codigo <code>pt</code> como
   * codigo inicial caso nenhum codigo de localidade tenho sido selecionado.
   */
  public init(): void {
    const localLang: string | null = this.getLocaleSelected();
    const lang = (localLang != null) ? localLang : this.DEFAULT_LANG;

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.registerLocale(event.lang);
      localStorage.setItem(this.KEY_LOCAL_LANG, event.lang);
    });

    this.translate.use(lang);
  }

  /**
   * @returns Retorna o Locale selecionado.
   */
  public getLocaleSelected(): string | null {
    return localStorage.getItem(this.KEY_LOCAL_LANG);
  }

  public setLocale(lang: string): void {
    this.translate.use(lang);
  }

  // tslint:disable-next-line:ban-types
  public instant(key: string | Array<string>, interpolateParams?: Object): string | any {
    return this.translate.instant(key, interpolateParams);
  }

  private registerLocale(lang: string): void {
    switch (lang) {
      case 'en':
        registerLocaleData(localeEn, lang);
        break;
      default:
        registerLocaleData(localePt);
        break;
    }
  }
}
