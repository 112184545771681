import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { Observable, Subscriber } from 'rxjs';
import { Domain } from 'src/app/shopping/models/domain';
import { IReview } from 'src/app/shopping/models/review';
import { OrderService } from 'src/app/shopping/services/order.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ReviewComponent),
    multi: true,
  }]
})
export class ReviewComponent implements ControlValueAccessor, Validator {

  @Input() title: string;
  @Input() enabled: boolean = true;

  oReview: IReview = {} as IReview;
  aDomains: Array<Domain> = new Array<Domain>();

  private propagateTouch = () => { };
  private propagateChange = (val: any) => { };

  constructor(private oOrderService: OrderService) { }

  writeValue(data: number | string): void {
    this.getDomain().subscribe(() => {
      setTimeout(() => {
        if (data) {
          const _inputElement = document.getElementById(`rating-${data}`);
          if (_inputElement) {
            _inputElement.setAttribute('value', String(data));
            _inputElement.click();
          }

          this.validateForm(data);
        }
      }, 100);
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  registerOnValidatorChange(fn: () => void): void { }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) { }
  }

  validate(c: AbstractControl) {
    if (!this.oReview?.valor) {
      return Validators.required;
    } else {
      return null;
    }
  }

  onSetReview(event: MouseEvent, element: any) {
    if (event) {
      this.oReview.valor = element.value;
      this.propagateChange(this.oReview.valor);
      this.propagateTouch();
    }
  }

  private validateForm(value: number | string) {
    setTimeout(() => {
      if (value && !this.enabled) {
        for (let index = 0; index < this.aDomains.length; index++) {
          const code = this.aDomains[index].type;
          const _inputElement = document.getElementById(`rating-${code}`);
          if (_inputElement) {
            _inputElement.setAttribute('disabled', 'true');
          }
        }
      }
    }, 200);
  }

  private getDomain() {
    return new Observable<any>((oSubscriber: Subscriber<any>) => {
      this.oOrderService.getDomainListByRequest().subscribe((res: any) => {
        if (res && res.length > 0) {
          this.aDomains = res;
        }
        oSubscriber.next();
        oSubscriber.complete();
      });
    })
  }

}
