import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { settings } from 'src/settings';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public readonly publicHeaderKey = 'X-Public-Request';
  public readonly noLoadingHeaderKey = 'X-No-Loading';

  constructor(private domSanitizer: DomSanitizer) { }

  public imageUrl(id?: string): SafeResourceUrl | null {
    if (id) {
      const domain = settings.endpoint.url;
      const contentManagerUrl = settings.contentManagerUrl.replace('#IMAGE_ID#', id);
      return this.domSanitizer.bypassSecurityTrustResourceUrl(`${domain}${contentManagerUrl}`);
    }
    return null;
  }

  public imageUrlToString(id?: string): string {
    if (id) {
      const domain = settings.endpoint.url;
      const contentManagerUrl = settings.contentManagerUrl.replace('#IMAGE_ID#', id);
      return `${domain}${contentManagerUrl}`;
    }
    return '';
  }

  public encode(data: string): string {
    return btoa(data);
  }

  public decode(data: string): string {
    return atob(data);
  }

  public requestPublic(noLoadingHeaderKey = false): HttpHeaders {
    let headers: any = {
      [this.publicHeaderKey]: 'yes'
    };
    if (noLoadingHeaderKey) {
      headers[this.noLoadingHeaderKey] = 'yes';
    }
    return new HttpHeaders(headers);
  }

  public msToMinutes(ms: number): number {
    const minutes = Number((ms / (1000 * 60)).toFixed(1));
    if (minutes < 60) {
      return minutes;
    } else {
      return 0;
    };
  }

  public generateRandomNumber(): string {
    const characters = '0123456789abcdef';
    let result = '0';

    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      const randomChar = characters.charAt(randomIndex);
      result += randomChar;
    }
    return result;
  }
}
