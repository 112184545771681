import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private readonly noLoadingKey = 'X-No-Loading';
  private validation: boolean;
  private loadingDialog: any = null;

  private pendingRequests = 0;

  constructor(private spinner: NgxSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const noLoading = req.headers.has(this.noLoadingKey);

    if (!noLoading) {
      if (req.url === '/comumLoja/lstDado') {
      } else if (req.url === '/parametroSistemaLoja/lstTpParametros') {
      } else if (req.url === '/produtoLoja/getProdutoSecao') {
        this.hideLoading();
      } else if (
        req.url.includes(
          'https://cerpagamentonline.emis.co.ao/online-payment-gateway/webframe/v1/frameToken/'
        )
      ) {
        this.hideLoading();
      } else if (req.url.includes('/emisGpo/checkTransaction')) {
        this.hideLoading();
      } else if (req.url  === '/vendasLoja/getHstPedido') {
        this.validation = true;
      } else if (req.url.includes('./assets/i18n/pt.json')) {
      } else if (req.url.includes("/emisGpo/checkTransaction")) {}
      else if (req.url.includes('/vendasLoja/lst')) {
      }
      else if (
        req.url.includes('perfil/getFotoPerfil') &&
        this.validation === true
      ) {
      } else {
      }
    }

    return next
      .handle(
        req.clone({
          headers: noLoading
            ? req.headers.delete(this.noLoadingKey)
            : req.headers,
        })
      )
      .pipe(finalize(() => !noLoading && this.hideLoading()));
  }

  private showLoading() {
    if (this.loadingDialog == null) {
      this.loadingDialog = this.spinner.show();
    }
    this.pendingRequests++;
  }

  private hideLoading() {
    this.pendingRequests--;
    if (this.loadingDialog != null && this.pendingRequests <= 0) {
      this.spinner.hide();
      this.loadingDialog = null;
    }
  }
}
