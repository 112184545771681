import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../services/currency.service';

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe implements PipeTransform {

  constructor(private oCurrencyService: CurrencyService) {
  }

  transform(value: any): string | null {
    return this.oCurrencyService.transformFromDB(String(value), 2);
  }

}