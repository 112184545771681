export const environment = {
  endpoint: {
    identityKey: 'YWduV2VvVVFkU2RSWjI5TnNKcTdnQ3BRUjBNYTo2ZmdxNU50NVRydWlnOFhOaHZXMHRTODdkYUlh',
    url: 'https://www32.siaf.co.ao'
  },
  contentManager: {
    rootFolder: 'dcb2b831-ebd2-406a-b88c-a7fedfae4e31'
  },
  type: 'HML',
  privacyPoliceUrl: 'https://hml.mypharma.co.ao/terms-conditions',
  contentManagerUrl: '/alfresco/nodes/#IMAGE_ID#/content/',
  contentManagerRootUrl: '/alfresco',
  scopes: 'openid '
};
