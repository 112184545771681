import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  constructor(
    private languageService: LanguageService
  ) { }

  ngOnInit() {
  }

  getLanguage() {
    return this.languageService.getLanguage();
  }

  setLanguageToPT() {
    this.languageService.setLanguageToPT();
    setTimeout(() => window.location.reload(), 200);
  }

  setLanguageToEN() {
    this.languageService.setLanguageToEN();
    setTimeout(() => window.location.reload(), 200);
  }

}
