<div class="dropdown" style="margin-right: 15px;">
  <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    <span>{{getLanguage() | uppercase}}</span> <i class="fa-solid fa-angle-down"></i>
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li>
      <a id="pt" class="dropdown-item" href="javascript:void(0)" (click)="setLanguageToPT()">Português</a>
    </li>
    <li>
      <a id="en" class="dropdown-item" href="javascript:void(0)" (click)="setLanguageToEN()">English</a>
    </li>
  </ul>
</div>