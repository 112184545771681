import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber, map, of } from 'rxjs';
import { CartProduct, ViewCartProducts } from 'src/app/shopping/models/cart-produt';
import { Product } from 'src/app/shopping/models/product';
import { ViewCartAdapter } from '../../shopping/adapters/view-cart.adapter';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ViewCartService {

  private readonly CART_KEY = 'CART_STORAGE';
  private readonly CART_URL: string = '/vendasLoja';

  cart: Product[] | null;
  cartSource = new BehaviorSubject<CartProduct[] | null>(null);
  cartSource$: Observable<CartProduct[] | null> = this.cartSource.asObservable();

  aCartProductsAux: Array<CartProduct> = [];

  constructor(
    private oHttpClient: HttpClient,
    private oDomSanitizer: DomSanitizer,
    private oViewCartAdapter: ViewCartAdapter,
  ) { }

  getCart(): Observable<ViewCartProducts> {
    const oParams = {} as any;

    return this.oHttpClient.get<any>(`${this.CART_URL}/getProdutosCarrinho`, { params: oParams }).pipe(map(item => {
      if (item && item.produtos) {
        const itemAux = { produtos: item.produtos.map((product: any) => this.oViewCartAdapter.adapter(product)), vlTotal: item.vlTotal };
        this.aCartProductsAux = itemAux.produtos;
        return itemAux;
      }
      return {} as ViewCartProducts;
    }));
  }

  registerCart(item: CartProduct): Observable<any> {
    const oParams = {} as any;

    if (item.idViewCart) {
      oParams['idLojaCarrinho'] = item.idViewCart;
      oParams['nuQuantidade'] = item.quantityViewCart ? item.quantityViewCart : item.quantity;

      return this.oHttpClient.put(`${this.CART_URL}/salvarProdutosCarrinho`, oParams).pipe(map((res: any) => {
        return res || of([]);
      }));
    } else {
      oParams['idClassificador'] = item.id;
      oParams['nuQuantidade'] = item.quantityViewCart ? item.quantityViewCart : item.quantity;

      return this.oHttpClient.put(`${this.CART_URL}/salvarProdutosCarrinho`, oParams).pipe(map((res: any) => {
        return res || of([]);
      }));
    }
  }

  updateCart(produtos?: Array<CartProduct | any>): Observable<any> {
    const oParams = {} as any;

    oParams['produtos'] = JSON.stringify(produtos);

    return this.oHttpClient.put(`${this.CART_URL}/salvarProdutosCarrinho`, oParams).pipe(map((res: any) => {
      return res || of([]);
    }));
  }

  deleteCart(idViewCart: number): Observable<any> {
    const oParams = {} as any;

    oParams['idLojaCarrinho'] = idViewCart;

    return this.oHttpClient.put(`${this.CART_URL}/salvarProdutosCarrinho`, oParams).pipe(map((res: any) => {
      return res || of([]);
    }));
  }

  getCartStorage(): Array<CartProduct> | null {
    const cart = localStorage.getItem(this.CART_KEY);

    if (cart) {
      const array: any = JSON.parse(cart);
      return array.map((item: any) => {
        const product: CartProduct = item;
        if (item.imageUrl) {
          product.imageUrl = this.oDomSanitizer.bypassSecurityTrustResourceUrl(item.imageUrl[Object.keys(item.imageUrl)[0]]);
        }
        return this.oViewCartAdapter.validate(product);
      });
    }
    return null;
  }

  setCartStorage(item: CartProduct) {
    let cart = this.getCartStorage();
    const newCart = new Array<CartProduct>();

    newCart.push(item);

    if (cart && cart.length) {
      const index = cart.findIndex((prod: CartProduct) => prod.id === item.id);

      if (index >= 0) {
        if ((item.quantityViewCart) && item.quantityViewCart >= 0) {
          cart[index].quantityViewCart = item.quantityViewCart + cart[index].quantityViewCart;
        } else {
          const value = String(item.quantityViewCart).replace('-', '');
          cart[index].quantityViewCart = cart[index].quantityViewCart - Number(value);
        }
      } else {
        cart = cart.concat(newCart);
      }
    } else {
      cart = newCart;
    }

    localStorage.setItem(this.CART_KEY, JSON.stringify(cart));
  }

  delCartStorage(id: number) {
    const cart = this.getCartStorage();

    if (cart) {
      const index = cart.findIndex((product: Product) => product.id === id);

      if (index >= 0) {
        cart.splice(index, 1);
        localStorage.setItem(this.CART_KEY, JSON.stringify(cart));
        this.cartSource.next(cart);
      }
    }
  }

  mapCartStorage() {
    return new Observable<any>((oObserver: Subscriber<any>) => {
      let aProducts: Array<any> = [];
      let aViewCart: Array<CartProduct> = [];
      const aCartStorage = this.getCartStorage();

      this.getCart().subscribe((res: ViewCartProducts) => {
        if (aCartStorage?.length) {
          if (res?.produtos) {
            res.produtos.forEach((item: CartProduct) => {
              const oProduct = aCartStorage?.find((val: CartProduct) => val.id === item.id);

              if (oProduct) {
                oProduct.quantity = (item.quantity ? oProduct.quantityViewCart + item.quantity : item.quantityViewCart);
                oProduct.idViewCart = item.idViewCart;
                aViewCart.push(oProduct);
              } else {
                aViewCart.push(item);
              }
            });

            const aCartAux = aCartStorage.filter((val: CartProduct) => !res.produtos.find((obj: CartProduct) => obj.id === val.id));
            aViewCart = aViewCart.concat(aCartAux);

            for (let i = 0; i < aViewCart.length; i++) {
              const product = aViewCart[i] as CartProduct;

              aProducts.push(
                product.idViewCart ?
                  { 'idLojaCarrinho': product.idViewCart, 'nuQuantidade': product.quantity } :
                  { 'idClassificador': product.id, 'nuQuantidade': product.quantityViewCart }
              );
            }

            this.updateCart(aProducts).subscribe(() => {
              this.clearCartStorage();
              oObserver.next();
            });
          } else {
            for (let i = 0; i < aCartStorage.length; i++) {
              const product = aCartStorage[i] as CartProduct;

              aProducts.push(
                product.idViewCart ?
                  { 'idLojaCarrinho': product.idViewCart, 'nuQuantidade': product.quantity } :
                  { 'idClassificador': product.id, 'nuQuantidade': product.quantityViewCart }
              );
            }

            this.updateCart(aProducts).subscribe(() => {
              this.clearCartStorage();
              oObserver.next();
            });
          }
        } else {
          oObserver.next();
        }
      });
    });
  }

  clearCartStorage() {
    localStorage.removeItem(this.CART_KEY);
  }

}
