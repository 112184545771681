import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient) { }

  public loadBase64Img(path: string): Observable<string> {
    return new Observable(observer => {
      this.http.get(path, { responseType: 'blob' }).subscribe(
        (blobResp: Blob) => {
          this.convertToBase64(blobResp).subscribe(base64Img => {
            observer.next(base64Img);
            observer.complete();
          });
        },
        error => {
          observer.error(error);
          observer.complete();
        }
      );
    });
  }

  private convertToBase64(blob: Blob): Observable<string> {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const result: any = reader.result;
        observer.next(result.split(',')[1]);
        observer.complete();
      }
    });
  }

}
