import { Injectable } from '@angular/core';
import { CompanyRepository } from '../repository/company.repository';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private companyRepository: CompanyRepository) { }


  img: string;
  imgLogin: string
  imgSingUp: string;

  setImgContact(img: string) {
    this.img = img;
  }

  getImgContact() {
    return this.img;
  }

  setImgLogin(imgLogin: string) {
    this.imgLogin = imgLogin;
  }

  getImgLogin() {
    return this.imgLogin;
  }

  setImgSingUp(imgSingUp: string) {
    this.imgSingUp = imgSingUp;
  }

  getImgSingUp() {
    return this.imgSingUp;
  }


  get company() {
    return this.companyRepository.getCompany();
  }

  get url() {
    return this.companyRepository.url();
  }

}
