import { Directive, HostListener, Input, Inject, ElementRef, forwardRef, Renderer2 } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaskedService } from '../services/masked.service';

@Directive({
  selector: '[appMasked]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MaskedDirective),
    multi: true
  }]
})
export class MaskedDirective implements ControlValueAccessor {

  @Input() appMasked: string = '';

  _onChange = (_: any) => { }

  constructor(@Inject(Renderer2) private renderer: Renderer2,
    @Inject(ElementRef) private element: ElementRef,
    @Inject(MaskedService) private maskedDirective: MaskedService) { }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    let nEnd = event.currentTarget.selectionEnd;
    const nLength = event.currentTarget.value.length;
    if (nEnd === nLength) {
      nEnd = -1
    }
    const sValor = this.formatValue(this.element.nativeElement.value)
    this.renderer.setProperty(this.element.nativeElement, 'value', sValor);
    if (nEnd !== -1) {
      nEnd = nEnd + (sValor.length - nLength)
      this.renderer.setProperty(this.element.nativeElement, 'selectionStart', nEnd)
      this.renderer.setProperty(this.element.nativeElement, 'selectionEnd', nEnd)
    }
    this._onChange(sValor)
  }

  @HostListener('blur')
  private onTouched: any;

  writeValue(value: any): void {
    this.renderer.setProperty(this.element.nativeElement, 'value', value || '')
  }

  registerOnChange(fn: (value: any) => any): void { this._onChange = fn }

  registerOnTouched(fn: () => any): void { this.onTouched = fn }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled)
  }

  private formatValue(sInputValue: string): string {
    let sValor = '';
    if (this.appMasked !== '') {
      sValor = this.maskedDirective.getMaskedValue(sInputValue, this.appMasked);
      this.renderer.setProperty(this.element.nativeElement, 'value', sValor);
    } else {
      sValor = sInputValue;
    }
    return sValor;
  }
}
