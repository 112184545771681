import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocaleService } from './locale.service';
import { MaskedService } from './masked.service';

@Injectable()
export class CurrencyService {
  private readonly CURRENCY_URL = './assets/data/currencies.json';
  private readonly CURRENCY_KEY = 'CURRENCY';
  private readonly CURRENCY_DEFAULT = 'AOA';

  constructor(
    private http: HttpClient,
    private oMaskedService: MaskedService,
    private oLocaleService: LocaleService,
  ) { }

  init() {
    this.setCurrency(this.getCurrency());
  }

  getCurrency(): string {
    const language = localStorage.getItem(this.CURRENCY_KEY);
    return language ? language : this.CURRENCY_DEFAULT;
  }

  getCurrenciesByRequests(): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>(this.CURRENCY_URL);
  }

  getCurrencies(): Observable<{ [key: string]: string }> {
    return new Observable(observer => {
      observer.next({
        "USD": "US Dollar",
        "EUR": "Euro",
        "GBP": "British Pound",
        "JPY": "Japanese Yen",
        "CNY": "Chinese Yuan",
        "CAD": "Canadian Dollar",
        "AUD": "Australian Dollar",
        "NZD": "New Zealand Dollar",
        "CHF": "Swiss Franc",
        "HKD": "Hong Kong Dollar",
        "SGD": "Singapore Dollar",
        "MYR": "Malaysian Ringgit",
        "THB": "Thai Baht",
        "IDR": "Indonesian Rupiah",
        "PHP": "Philippine Peso",
        "INR": "Indian Rupee",
        "MXN": "Mexican Peso",
        "ARS": "Argentine Peso",
        "CLP": "Chilean Peso",
        "COP": "Colombian Peso",
        "PEN": "Peruvian Sol",
        "ZAR": "South African Rand",
        "NGN": "Nigerian Naira",
        "EGP": "Egyptian Pound",
        "AOA": "Angolan Kwanza",
        "BRL": "Brazilian Real"
      });
    });
  }

  setCurrency(type: string): void {
    localStorage.setItem(this.CURRENCY_KEY, type);
  }

  transformFromDB(sValueDB: string, nDecimal: any) {
    let sValue = '';
    if (sValueDB.indexOf(',') === - 1 && sValueDB.indexOf('.') === - 1) {
      sValue = `${sValueDB},${`0`.repeat(nDecimal)}`;
    } else {
      sValue = sValueDB;
    }

    let sCents;
    if (sValue.split(',')[1]) {
      sCents = `${sValue.split(',')[1]}${`0`.repeat(nDecimal)}`.substr(0, nDecimal);
    } else {
      sCents = '0'.repeat(nDecimal);
    }

    return sValue.split(',')[0];
  }

  getCurrencyValue(
    sInputValue: string,
    nIntPos?: number,
    nDecimalPos: number = 2,
    nMinValue?: number,
    nMaxValue?: number): string {
    let sValor: string | null = sInputValue;
    let bNegative = false;

    if (sValor.charAt(0) === '-') {
      bNegative = true;
    }
    sValor = sValor ? sValor.replace(/[^0-9]/g, '').replace(/^[0]*/g, '') : null;

    if (!sValor) {
      return nMinValue ?
        this.formatNumber(nMinValue.toString().split('.')[0],
          nMinValue.toString().split('.')[1] || '0', nDecimalPos) :
        this.formatNumber('0', '0', nDecimalPos);
    }
    sValor = sValor.substr(0, 17);
    let sInteger = sValor.substr(0, sValor.length - nDecimalPos);
    if (nIntPos && sInteger.length > nIntPos) {
      sInteger = sInteger.substr(0, nIntPos);
    }
    const sDecimal = sValor.slice(- nDecimalPos);
    let sCent = sInteger.slice(-3);
    let sNewValue = '';
    while (sCent !== '.') {
      sNewValue = sCent + sNewValue;
      sInteger = sInteger.substr(0, sInteger.length - 3);
      sCent = sInteger.slice(-3) + '.';
    }
    sNewValue = this.formatNumber(sNewValue, sDecimal, nDecimalPos, bNegative);
    const nValue = this.getFloatValue(sNewValue);
    if (nMaxValue && nValue > nMaxValue) {
      sNewValue = this.formatNumber(nMaxValue.toString().split('.')[0],
        nMaxValue.toString().split('.')[1] || '0', nDecimalPos, bNegative);
    } else if (nMinValue && nValue < nMinValue) {
      sNewValue = this.formatNumber(nMinValue.toString().split('.')[0],
        nMinValue.toString().split('.')[1] || '0', nDecimalPos, bNegative);
    }
    return sNewValue;
  }

  getFloatValue(sValue: string) {
    return parseFloat(sValue);
  }

  private formatNumber(sIntegerPart?: string, sDecimalPart?: string, nDecimalPositions: number = 2, bNegative?: boolean) {
    const sDecimal = (nDecimalPositions > 0 ? '.' +
      ('0'.repeat(nDecimalPositions) + sDecimalPart).slice(-nDecimalPositions) : '');
    return (bNegative ? '-' : '') + (sIntegerPart || '0') + sDecimal;
  }

  formatLocale(value: any) {
    let sResult: string;
    let sPattern = this.oLocaleService.instant('common.formatation.currency_value');
    sPattern = sPattern.slice(sPattern.length - value.length);
    sResult = this.oMaskedService.getMaskedValue(value, sPattern);
    return sResult;
  }
}