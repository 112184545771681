import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FicheiroService } from '../../services/ficheiro.service';

@Component({
  selector: 'app-file-viewer-gallery',
  templateUrl: './file-viewer-gallery.component.html',
  styleUrls: ['./file-viewer-gallery.component.scss']
})
export class FileViewerGalleryComponent implements OnInit {

  @Input() files: Array<any> = new Array<any>();

  _slideIndex: number = 1;
  _settings: string = environment.endpoint.url;

  constructor(
    private oDomSanitizer: DomSanitizer,
    private oFicheiroService: FicheiroService
  ) { }

  ngOnInit(): void {
    if (this.files && this.files.length > 0) {
      window.setTimeout(() => {
        this.getFiles();
        this.getPages(this._slideIndex);
      });
    }
  }

  onPagination(index: number) {
    this.getPages(this._slideIndex += index);
  }

  getLabelIndexPage(index: number) {
    return `${index + 1} / ${this.files.length}`;
  }

  getUrlImage(contentId: string) {
    return this.oDomSanitizer.bypassSecurityTrustResourceUrl(`${this._settings}/alfresco/nodes/${contentId}/content?attachment=false`);
  }

  private getUrlFrame(contentId: string) {
    return (`https://docs.google.com/gview?url=${this._settings}/alfresco/nodes/${contentId}/content?attachment=false&embedded=true`);
  }

  private getPages(index: number) {
    const slides = document.getElementsByClassName('slides') as any;

    if (index > slides.length) {
      this._slideIndex = 1;
    }
    if (index < 1) {
      this._slideIndex = slides.length;
    }
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[this._slideIndex - 1].style.display = "block";
  }

  private getFiles() {
    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];

      this.oFicheiroService.getContent(file.contentId).subscribe((blob: Blob) => {
        const reader = new FileReader();
        const div = document.getElementById(`slide-${index}`);

        reader.onload = (blob) => {
          const res = blob?.target?.result as string;
          const types = ['PDF', 'txt', 'xml'];

          if (!types.find((type: string) => res.includes(type))) {
            file.type = 'img';
            return file;
          }
        };

        reader.readAsText(blob);

        if (div && !file?.type) {
          const frame = document.createElement('iframe');

          frame.setAttribute('style', 'width: 100%; height: 450px;');
          frame.setAttribute('frameBorder', '0');
          frame.src = this.getUrlFrame(file.contentId) as string;
          div.appendChild(frame);
        }
      });
    }
  }

}
