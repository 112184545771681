import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CompanyRepository } from "../repository/company.repository";

@Injectable({
  providedIn: 'root'
})
export class CompanyResolver  {
  constructor(private companyRepository: CompanyRepository) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.companyRepository.resolve();
  }
}
