import { Component, OnInit } from '@angular/core';
import { CookiesService } from '../../services/cookies.service';
import { settings } from 'src/settings';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  privacyPoliceUrl = settings.privacyPoliceUrl;
  hideCookieDialog = true;

  constructor(
    private cookiesService: CookiesService
  ) { }

  ngOnInit() {
    this.hideCookieDialog = this.cookiesService.isAcceptedCookie();
  }

  acceptCookies() {
    this.cookiesService.acceptCookie();
    document.getElementById('accept-cookies')?.remove();
  }

}
