<form [formGroup]="oForm">
  <div *ngIf="simple; else moreDetails;" [ngClass]="{'price-qty-custom': style}" class="price-qty">
    <div [ngClass]="{'counter-number-custom': style}" class="counter-number">
      <div class="counter">
        <div (click)="onChangeCount('LESS')" class="qty-left-minus" data-type="minus" data-field="">
          <i class="fa-solid fa-minus"></i>
        </div>
        <input formControlName="quantity" class="form-control input-number qty-input input-counter" type="number"
          name="quantity">
        <div (click)="onChangeCount('MORE')" class="qty-right-plus" data-type="plus" data-field="">
          <i class="fa-solid fa-plus"></i>
        </div>
      </div>
    </div>

    <button [ngClass]="{'buy-button-custom': style}" (click)="onAddCart()" class="buy-button buy-button-2 btn btn-cart">
      <i [ngClass]="{'iconly-Buy-custom': style}" class="iconly-Buy icli text-white m-0"></i>
    </button>
  </div>

  <ng-template #moreDetails>
    <div class="counter-number">
      <div class="counter">
        <div (click)="onChangeCount('LESS')" class="qty-left-minus" data-type="minus" data-field="">
          <i class="fa-solid fa-minus"></i>
        </div>
        <input formControlName="quantity" class="form-control input-number qty-input input-counter" type="number"
          name="quantity">
        <div (click)="onChangeCount('MORE')" class="qty-right-plus" data-type="plus" data-field="">
          <i class="fa-solid fa-plus"></i>
        </div>
      </div>
    </div>
    <div class="modal-button">
      <button (click)="onAddCart()" [disabled]="!limit" class="btn btn-md add-cart-button icon">
        {{'products.add_cart' | translate}}
      </button>
      <button (click)="view.emit(true)" class="btn theme-bg-color view-button icon text-white fw-bold btn-md"
        data-bs-dismiss="modal">
        {{'products.more_details' | translate}}
      </button>
    </div>
  </ng-template>
</form>

<div appDynamicScript>
  <div src="./assets/js/components/cart-button.component.js"></div>
</div>