import { UserProfileAdapter } from './../../../core/adapters/user.profile.adapter';
import { Endereco } from './../../../shared/model/endereco';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { accountPage } from '../models/account';
import { UserContacts } from 'src/app/core/models/user-contacts';
import { UserContactsAdapter } from 'src/app/core/adapters/user-contacts.adapter';
import { UserProfile } from 'src/app/core/models/user-profile';

@Injectable()

export class AccountService {

  private readonly sUrl_vendasLoja = '/vendasLoja';
  private readonly sUrl_pessoaLoja = '/pessoaLoja';
  private readonly sPath = '/perfil';
  loadPedidos: accountPage | null;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private oUserContactsAdapter: UserContactsAdapter,
    private oUserProfileAdapter: UserProfileAdapter
  ) {
    this.reset();
  }

  reset(): void {
    this.loadPedidos = null;
  }

  detailPedidos() {
    const oParams = {} as any;
    if (this.loadPedidos) {
      if (this.loadPedidos.flLstHistorico) {
        oParams['flLstHistorico'] = this.loadPedidos.flLstHistorico;
      }
      if (this.loadPedidos.flLstProdutos) {
        oParams['flLstProdutos'] = this.loadPedidos.flLstProdutos;
      }
      if (this.loadPedidos.flLstEndParceiro) {
        oParams['flLstEndParceiro'] = this.loadPedidos.flLstEndParceiro;
      }
      if (this.loadPedidos.flLstEndCliente) {
        oParams['flLstEndCliente'] = this.loadPedidos.flLstEndCliente;
      }
      if (this.loadPedidos.flLstLogoParceiro) {
        oParams['flLstLogoParceiro'] = this.loadPedidos.flLstLogoParceiro;
      }
      if (this.loadPedidos.flSistema) {
        oParams['flSistema'] = this.loadPedidos.flSistema;
      }
      if (this.loadPedidos.orderBy) {
        oParams['orderBy'] = this.loadPedidos.orderBy;
      }
      if (this.loadPedidos.idCliente) {
        oParams['idCliente'] = this.loadPedidos.idCliente;
      }
      if (this.loadPedidos.idContrato) {
        oParams['idContrato'] = this.loadPedidos.idContrato;
      }
    }
    return this.http.get(`${this.sUrl_vendasLoja}/lst`, { params: oParams }).pipe(
      map((oRet: any) => {
        return oRet || [];
      }));
  }

  saveEndereco(dataEndereco: Endereco) {
    const oParams = {} as any;

    if (dataEndereco) {
      if (dataEndereco.idPesEndereco) {
        oParams['idPesEndereco'] = dataEndereco.idPesEndereco;
      }
      if (dataEndereco.dsMorada) {
        oParams['dsEndereco'] = dataEndereco.dsMorada;
      }
      if (dataEndereco.dsPtoReferencia) {
        oParams['dsPtoReferencia'] = dataEndereco.dsPtoReferencia;
      }
      if (dataEndereco.flPrincipal) {
        oParams['flPrincipal'] = dataEndereco.flPrincipal;
      }
      if (dataEndereco.flCorrespondencia) {
        oParams['flCorrespondencia'] = dataEndereco.flCorrespondencia;
      }
      if (dataEndereco.idPais) {
        oParams['idPais'] = dataEndereco.idPais;
      }
      if (dataEndereco.idComuna) {
        oParams['idLocalidade'] = dataEndereco.idComuna;
      }
      if (dataEndereco.tpEndereco) {
        oParams['tpEndereco'] = dataEndereco.tpEndereco;
      }
    }

    // Update
    if (dataEndereco.idPesEndereco) {
      return this.http.put(`${this.sUrl_pessoaLoja}/updPessoaEndereco`, oParams).pipe(
        map((oRet: any) => {
          return oRet || of([]);
        }));
    }
    // Insert
    else if (!dataEndereco.idPesEndereco) {
      return this.http.post(`${this.sUrl_pessoaLoja}/insPessoaEndereco`, oParams).pipe(
        map((oRet: any) => {
          return oRet || of([]);
        }));
    }
    return of([])
  }

  deleteEndereco(idPesEndereco: number) {
    const oParam = {} as any;
    oParam['idPesEndereco'] = idPesEndereco;

    return this.http.delete(`${this.sUrl_pessoaLoja}/delPessoaEndereco?idPesEndereco=${idPesEndereco}`);
  }

  getEndereco() {
    const oParam = {} as any;

    return this.http.get(`${this.sUrl_pessoaLoja}/getEndereco`, { params: oParam }).pipe(
      map((oRet: any) => {
        return oRet || [];
      }));
  }

  getLocalidade(idLocalidadePai?: number, idPais?: any): Observable<any[]> {
    let oParams = new HttpParams();
    if (idLocalidadePai)
      oParams = oParams.set('idLocalidadePai', idLocalidadePai);
    else
      oParams = oParams.set('idPais', idPais);

    return this.http.get(`${this.sUrl_pessoaLoja}/lstLocalidade`, { params: oParams }).pipe(
      map((item: any) => {
        if (item.lines && item.lines.length) {
          return item.lines.map((localidade: any) => { return localidade });
        }
        return [];
      }));
  }

  renderImage(contentId: any) {
    return this.utilsService.imageUrlToString(contentId) || null;
  }

  getUserContactsByRequest(): Observable<UserContacts[]> {
    const oParams = {};

    return this.http.get(`${this.sUrl_pessoaLoja}/lstContato`, { params: oParams }).pipe(map((item: any) => {
      if (item && item.lines && item.lines.length) {
        return item.lines.map((contact: any) => this.oUserContactsAdapter.adapter(contact));
      }
      return [];
    }));
  }

  getUserProcedureByRequest(): Observable<any[]> {
    const oParams = {};

    return this.http.get(`${this.sUrl_pessoaLoja}/lstProcedimentos`, { params: oParams }).pipe(map((item: any) => {
      return item || of([]);
    }));
  }

  saveContacts(contacts: UserContacts) {
    const oParams = {} as any;

    oParams['dsContato'] = contacts.description;

    if (contacts.id) {
      oParams['idContato'] = contacts.id;

      return this.http.put(`${this.sUrl_pessoaLoja}/updContato`, oParams).pipe(map((res: any) => {
        return res || of([]);
      }));
    } else {
      oParams['tpContato'] = contacts.type.type;

      return this.http.post(`${this.sUrl_pessoaLoja}/insContato`, oParams).pipe(map((res: any) => {
        return res || of([]);
      }));
    }
  }

  getPersonInfoByRequest() {
    const oParams = {};

    return this.http.get(`${this.sUrl_pessoaLoja}/getInfoPessoa`, { params: oParams }).pipe(
      map((item: any) => {
        return this.oUserProfileAdapter.adapter(item) || [];
      }));
  }

  getFotoPerfil() {
    return this.http.get(`${this.sPath}/getFotoPerfil`).pipe(
      map((res: any) => {
        return res || of([]);
      }));
  }

  insFotoPerfil(file: any) {
    //
  }

  savePersonInfo(profile: UserProfile) {
    const oParams = {} as any;

    oParams['noPessoa'] = null;
    oParams['dtNascimento'] = profile.birthDate;
    oParams['tpSexo'] = profile.gender;

    return this.http.put(`${this.sUrl_pessoaLoja}/atualizaInfoPessoa`, oParams).pipe(map((res: any) => {
      return res || of([]);
    }));
  }
}
