import { Injectable } from '@angular/core';

export interface SlugifyPipeOptions {
  unique?: boolean;
  uniqueOptions?: {
    randomLength?: number;
    chars?: Array<'lowercase' | 'uppercase' | 'numeric' | 'special'>;
    separator?: string;
  };
}

export enum CharTypesEnum {
  lowercase = 'a',
  uppercase = 'A',
  numeric = '#',
  special = '!'
}

@Injectable()
export class SlugfyService {

  constructor() { }

  transform(input: string): string {

    // Default values
    const unique = false;
    const { chars, randomLength, separator } = {
      randomLength: 6,
      chars: 'lowercase',
      separator: '-'
    };

    let randomChars: string = '';
    unique && chars

    const trChars: any = {
      'áÁ': 'a',
      'éÉ': 'e',
      'íÍ': 'i',
      'óÓ': 'o',
      'úÚ': 'u',
      'ñÑ': 'n'
    };
    for (const key of Object.keys(trChars)) {
      input = input.replace(new RegExp('[' + key + ']', 'g'), trChars[key]);
    }
    return input
      .toString()
      .toLowerCase()
      .replace(/[^\w-]+/g, ' ')
      .replace(/-+$/, '')
      .replace(/^-+/, '')
      .replace(/--+/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .concat(unique ? separator + this.randomString(randomLength, randomChars) : '');
  }

  private randomString(length: number, chars: string) {
    let result = '';
    let mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';

    for (let i = length; i > 0; --i)
      result += mask[Math.floor(Math.random() * mask.length)];
    return result;
  }

}


