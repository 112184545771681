import { Injectable } from "@angular/core";
import { DocFiscal } from "../models/doc-fiscal";

@Injectable()
export class FiscalDocumentAdapter {

  constructor() { }

  adapter(raw: any): DocFiscal {
    const doc_fiscal: DocFiscal = {
      name: raw['name'] || null,
      mimeType: raw['mimeType'] || null,
      size: raw['size'] || null,
      content: raw['content'] || null,
    };

    return this.validate(doc_fiscal);
  }

  private validate(doc_fiscal: DocFiscal): DocFiscal {
    if (!doc_fiscal.name || !doc_fiscal.mimeType || !doc_fiscal.size || !doc_fiscal.content) {
      throw new Error('Error creating sale, all attributes must be filled in.');
    }
    return doc_fiscal;
  }

}
