import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  urlSource = new BehaviorSubject<string | null>(null);
  urlSource$: Observable<string | null> = this.urlSource.asObservable();

  constructor() { }

  setUrlRoute(route: string) {
    this.urlSource.next(route);
  }

}
