import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay, tap } from 'rxjs';
import { CategoryAdapter } from '../adapters/category.adapter';
import { Category } from '../models/category';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { Company } from 'src/app/core/models/company';

@Injectable()
export class CategoryService {
  private categoryList$: Observable<Category[]>;
  private readonly CATEGORY_URL = '/comumLoja/lstDado';
  static CategoryAdapter: any;
  private company: Company | null;

  constructor(
    private http: HttpClient,
    private categoryAdapter: CategoryAdapter,
    private utilsService: UtilsService,
    private companyService: CompanyService,
  ) {
    this.company = this.companyService.company;
  }

  getCategoryListByRequest(type?: string): Observable<Category[]> {
    if (!this.categoryList$) {
      const oParams = {} as any;
      oParams['sgPessoaSiaf'] = this.company?.id;
      oParams['sgTpDado'] = type ? type : 'TP_CATEGORIAS';
      oParams['flAtivo'] = 'S';

      this.categoryList$ = this.http.get<any>(this.CATEGORY_URL, { headers: this.utilsService.requestPublic(), params: oParams }).pipe(
        map(item => {
          if (item.items && item.items.length) {
            return item.items.map((category: any) => this.categoryAdapter.adapter(category));
          }
          return [];
        }),
        shareReplay(1)
      );
    }

    return this.categoryList$;
  }

}
