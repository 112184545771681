import { ViewCartService } from './../../services/view-cart.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs';
import { ProductsService } from 'src/app/shopping/services/products.service';
import Swal from 'sweetalert2';
import { ProdutoLog } from '../../enums/produto-log';
import { CartProduct } from 'src/app/shopping/models/cart-produt';

@Component({
  selector: 'app-cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.sass']
})
export class CartButtonComponent implements OnInit {

  @Input() style: boolean = false;
  @Input() simple: boolean = false;
  @Input() limit!: number | undefined;
  @Input() idProduct!: number | undefined;

  @Output() cart: EventEmitter<any> = new EventEmitter<any>();
  @Output() view: EventEmitter<boolean> = new EventEmitter<boolean>();

  oForm: FormGroup;

  aCartProducts: Array<CartProduct> = [];

  constructor(
    oFormBuilder: FormBuilder,
    private oTranslateService: TranslateService,
    private oViewCartService: ViewCartService,
    private oProductsService: ProductsService,
  ) {
    this.oForm = oFormBuilder.group({
      quantity: [0, [Validators.required, Validators.min(1)]]
    });

    this.quantity.valueChanges.pipe(distinctUntilChanged()).subscribe((value: number) => {
      let arr: CartProduct = {} as CartProduct;

      if (this.oViewCartService.aCartProductsAux && this.oViewCartService.aCartProductsAux.length > 0) {
        arr = this.oViewCartService.aCartProductsAux?.find((oRet: any) => oRet.id === this.idProduct) as any;
      }
      if (Number(arr?.quantity) <= (arr?.quantityViewCart + value)) {
        this.quantity.setValue(Number(arr?.quantity) - arr?.quantityViewCart);
      }

      if ((value && this.limit) && value > this.limit) {
        this.quantity.setValue(this.limit);
      }
    })
  }

  ngOnInit() {
    if (!this.limit) {
      this.quantity.disable();
    }
  }

  onAddCart() {
    if (this.oForm.valid && this.quantity.value > 0) {
      this.cart.emit({ quantity: this.quantity.value, action: !this.simple });
      this.quantity.setValue(0);
      if (this.idProduct) {
        this.oProductsService.insProdutoLog(this.idProduct, ProdutoLog.ADDCLASS).subscribe();
      }
    } else {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.oTranslateService.instant('common.warning'),
        text: this.oTranslateService.instant('products.message.required_product'),
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
  }

  onChangeCount(param: string) {
    switch (param) {
      case 'MORE':
        if (!this.getValidStock) {
          this.quantity.setValue(this.quantity.value + 1);
        }
        break;
      case 'LESS':
        if (this.quantity.value > 0) {
          this.quantity.setValue(this.quantity.value - 1);
        }
        break;
    }
  }

  get quantity(): FormControl {
    return this.oForm.get('quantity') as FormControl;
  }
  get getValidStock(): any {
    if (this.limit) {
      return this.quantity.value >= this.limit;
    }
    return true;
  }
}
