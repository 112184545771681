import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class SystemStoreService {

  private SISTEMALOJA_URL = '/parametroSistemaLoja/lstTpParametros';
  private dados$: Observable<any>;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService
  ) { }

  getparametroSistemaLojaListByRequest(): Observable<any> {
    if (!this.dados$) {
      const oParams = {} as any;
      oParams['tpModulo'] = 'EC';
      oParams['sgPessoaSiaf'] = localStorage.getItem('sgPessoaSiaf');

      this.dados$ = this.http.get<any>(this.SISTEMALOJA_URL, { headers: this.utilsService.requestPublic(), params: oParams })
        .pipe(
          shareReplay(1)
        );
    }

    return this.dados$;
  }
}
