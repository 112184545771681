import { Injectable } from "@angular/core";
import { UserAddress } from "../models/user-address";
import { UserLocality } from "../models/user-locality";

@Injectable({
  providedIn: 'root'
})
export class UserAddressAdapter {

  constructor(
  ) { }

  public adapter(raw: any): UserAddress {
    const userAddress: UserAddress = {
      id: raw['idEndereco'] || null,
      idCompany: raw['idPesEndereco'] || null,
      address: raw['dsEndereco'] || null,
      mainAddress: (raw['flPrincipal'] && raw['flPrincipal']['codigo'] === 'S') ? true : false || false,
      correspondenceAddress: (raw['flCorrespondencia'] && raw['flCorrespondencia']['codigo'] === 'S') ? true : false || false,
      numberAddress: raw['nuEndereco'] || null,
      country: {
        id: raw['pais']['idPais'] || null,
        name: raw['pais']['noPais'] || null,
        acronym: raw['pais']['sgPais'] || null,
        ddi: raw['pais']['nuDdi'] || null
      },
      locality: (raw['localidade']) ? raw['localidade'].map((item: any) => {
        const userLocality: UserLocality = {
          id: item['idLocalidade'],
          name: item['noLocalidade'],
          type: {
            id: item['tpLocalidade']['idTpLocalidade'],
            name: item['tpLocalidade']['noTpLocalidade']
          }
        };
        return userLocality;
      }) : []
    };
    return this.validate(userAddress);
  }

  public validate(userAddress: UserAddress): UserAddress {
    if (!userAddress.id || !userAddress.idCompany || !userAddress.country.id || !userAddress.address) {
      throw new Error('Error creating user address, all required attributes must be filled in.');
    }
    return userAddress;
  }

}
