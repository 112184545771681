import { Injectable } from "@angular/core";
import { Country } from "src/app/core/models/country";

@Injectable()
export class CountryAdapter {

  constructor() { }

  adapter(raw: any): Country {
    const country: Country = {
      id: raw['idPais'] || null,
      name: raw['noPais'] || null,
      acronym: raw['sgPais'] || null,
      ddi: raw['nuDdi'] || null
    };
    return this.validate(country);
  }

  private validate(object: Country): Country {
    if (!object.id || !object.name) {
      throw new Error('Error creating country, all attributes must be filled in.');
    }
    return object;
  }

}
