import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { TokenRepository } from "../repository/token.repository";

@Injectable({
  providedIn: 'root'
})
export class TokenResolver  {
  constructor(private tokenRepository: TokenRepository) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.tokenRepository.resolve();
  }
}
