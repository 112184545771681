import { Injectable } from '@angular/core';

@Injectable()
export class MaskedService {

  getMaskedValue(sValue: string, sMask: string): string {
    if (!sMask || !sValue) {
      return sValue;
    }

    const sPattern = sMask
      .replace(/[9a]/g, '')
      .split('')
      .filter((item, pos, self) => {
        return self.indexOf(item) === pos;
      })
      .join('');

    const sVal: string[] = sValue
      .trim()
      .replace(new RegExp(`[${sPattern}]`, 'g'), '')
      .split('');

    let i = 0;
    while (i < sVal.length) {
      // tslint:disable-next-line:radix
      if ((sMask[i] === '9' && isNaN(Number.parseInt(sVal[i]))) || !sMask[i]) {
        sVal.splice(i, 1);
        i--;
      } else if (sMask[i] !== 'a' && sMask[i] !== '9') {
        sVal[sVal.length] = sVal[sVal.length - 1];
        for (let j = sVal.length - 2; j > i; j--) {
          sVal[j] = sVal[j - 1];
        }
        sVal[i] = sMask[i];
      }
      i++;
    }
    return sVal.join('');
  }

  getUnmaskedValue(sValue: string, sMask: string): string {
    if (!sMask || !sValue) {
      return sValue;
    }
    const sPattern = sMask
      .replace(/[9a]/g, '')
      .split('')
      .filter((item, pos, self) => {
        return self.indexOf(item) === pos;
      })
      .join('');
    return sValue.trim().replace(new RegExp(`[${sPattern}]`, 'g'), '');
  }

}
