import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { UserProfile } from "../models/user-profile";

@Injectable({
  providedIn: 'root'
})
export class UserProfileAdapter {

  constructor(
  ) { }

  public adapter(raw: any): UserProfile {
    const userProfile: UserProfile = {
      fullName: raw['noPessoa'] || null,
      documentNumber: raw['documento']['numero'] || null,
      documentType: raw['documento']['sigla'] || null,
      documentName: raw['documento']['nome'] || null,
      birthDate: raw['dtNascimento'] || null,
      gender: raw['tpSexo'] == null ? null : raw['tpSexo'].valor,
      documentCountry: {
        id: raw['documento']['pais']['id'] || null,
        name: raw['documento']['pais']['nome'],
        acronym: raw['documento']['pais']['sigla'],
        ddi: raw['documento']['pais']['ddi']
      }
    };
    return this.validate(userProfile);
  }

  public validate(userProfile: UserProfile): UserProfile {
    if (!userProfile.fullName || !userProfile.documentNumber) {
      throw new Error('Error creating user, all required attributes must be filled in.');
    }
    return userProfile;
  }

}
