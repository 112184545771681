import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../core/core.module';
import { FiscalDocumentAdapter } from '../shopping/adapters/doc-fiscal.adapter';
import { CheckoutService } from '../shopping/services/checkout.service';
import { ShoppingModule } from '../shopping/shopping.module';
import { CountryAdapter } from './adapters/country.adapter';
import { ButtonGroupComponent } from './components/button-group/button-group.component';
import { CartButtonComponent } from './components/cart-button/cart-button.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { CurrencySelectorComponent } from './components/currency-selector/currency-selector.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { ViewCartComponent } from './components/view-cart/view-cart.component';
import { DynamicScriptDirective } from './directives/dynamic-script.directive';
import { MaskedDirective } from './directives/masked.directive';
import { AppCurrencyPipe } from './pipes/currency.pipe';
import { CountryRepository } from './repository/country.repository';
import { ContentService } from './services/content.service';
import { CookiesService } from './services/cookies.service';
import { CountryService } from './services/country.service';
import { CurrencyService } from './services/currency.service';
import { LanguageService } from './services/language.service';
import { LocaleService } from './services/locale.service';
import { MaskedService } from './services/masked.service';
import { SlugfyService } from './services/slugfy.service';
import { UtilsService } from './services/utils.service';
import { PaymentQrCodeAdapter } from '../shopping/adapters/qr-code.adapter';
import { SystemStoreService } from './services/sistema-loja';
import { FileViewerGalleryComponent } from './components/file-viewer-gallery/file-viewer-gallery.component';
import { ReviewComponent } from './components/review/review.component';


@NgModule({
  declarations: [
    HeaderComponent,
    LanguageSelectorComponent,
    CurrencySelectorComponent,
    CookiesComponent,
    DynamicScriptDirective,
    FooterComponent,
    AppCurrencyPipe,
    ButtonGroupComponent,
    AppCurrencyPipe,
    ViewCartComponent,
    MaskedDirective,
    CartButtonComponent,
    FileViewerGalleryComponent,
    ReviewComponent
  ],
  exports: [
    HeaderComponent,
    LanguageSelectorComponent,
    CurrencySelectorComponent,
    CookiesComponent,
    DynamicScriptDirective,
    FooterComponent,
    AppCurrencyPipe,
    ButtonGroupComponent,
    MaskedDirective,
    CartButtonComponent,
    FileViewerGalleryComponent,
    ReviewComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    TranslateModule,
    ShoppingModule,
    CoreModule,
    ReactiveFormsModule
  ],
  providers: [
    LanguageService,
    CurrencyService,
    CookiesService,
    UtilsService,
    SlugfyService,
    ContentService,
    MaskedService,
    LocaleService,
    CheckoutService,
    CountryAdapter,
    CountryService,
    CountryRepository,
    FiscalDocumentAdapter,
    PaymentQrCodeAdapter,
    SystemStoreService
  ]
})
export class SharedModule { }
