import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { ApplicationCredential } from "../models/application-credential";

@Injectable({
  providedIn: 'root'
})
export class TokenAdapter {

  public validate(token: ApplicationCredential): ApplicationCredential {
    if (!token || !token.access_token) {
      throw new Error('Error creating token application, all required attributes must be filled in.');
    }
    return token;
  }

}
