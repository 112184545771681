import { Injectable } from '@angular/core';
import { UserRepository } from '../repository/user.repository';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserCredential } from '../models/user-credential';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { settings } from 'src/settings';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private readonly API_TOKEN = '/token';

  // Access Token is renew each 20 minutes
  private static EXPIRE_TOKEN_MINUTES = 20;

  // Time for user session revalidation and inactivity check.
  private static TIME_TO_CHECK_INACTIVITY_MILLISECONDS = 10 * 60 * 1000;

  private isActive = false;
  private lastActionAt = Date.now();
  private block = false;

  constructor(
    private userRepository: UserRepository,
    private router: Router,
    private http: HttpClient,
    private utilsService: UtilsService
  ) { }

  init() {
    window.onclick = () => {
      this.isActive = true;
    };
    window.onkeypress = () => {
      this.isActive = true;
    };
    window.onmousemove = () => {
      this.isActive = true;
    };
    this.start();
    setInterval(() => this.start(), TokenService.TIME_TO_CHECK_INACTIVITY_MILLISECONDS);
  }

  start() {
    const user = this.userRepository.getUser();
    if (!this.router.url.includes('login')) {
      if (this.isActive) {
        this.lastActionAt = Date.now();
      }
      this.isActive = false;
      if (!this.block) {
        this.validateSessionExpiration(user);
      }
    }
  }

  validateSessionExpiration(user: User | null) {
    if (user) {
      if (user.credential.expires_in && user.credential.created_at) {
        // Renew user token using JWT Token
        const expiresTokenMilliseconds = this.utilsService.msToMinutes(Date.now() - user.credential.created_at);
        if (expiresTokenMilliseconds > TokenService.EXPIRE_TOKEN_MINUTES) {
          this.block = true;
          this.renewToken(user).then((result) => {
            if (result) {
              this.block = false;
            } else {
              throw new Error('Not possible renew user token!');
            }
          });
        }
      }
    }
  }

  async renewToken(user: User): Promise<boolean> {
    return new Promise(resolve => {
      this.renewUserToken(user.credential.refresh_token).subscribe(credential => {
        try {
          user.credential.access_token = credential.access_token;
          user.credential.created_at = credential.created_at;
          user.credential.expires_in = credential.expires_in;
          user.credential.id_token = credential.id_token;
          user.credential.refresh_token = credential.refresh_token;
          user.credential.scope = credential.scope;
          user.credential.token_type = credential.token_type;
          this.userRepository.save(user);
          resolve(true);
        } catch (e) {
          resolve(false);
        }
      });
    });
  }

  renewUserToken(refreshToken: string): Observable<UserCredential> {
    const params = `grant_type=refresh_token&refresh_token=${refreshToken}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'X-No-Loading': 'hidden',
      'Authorization': `Basic ${settings.endpoint.identityKey}`
    });
    return this.http.post<UserCredential>(this.API_TOKEN, params, { headers }).pipe(
      map(res => Object.assign(res, { created_at: Date.now() }))
    );
  }
}
