<!-- Cookie Bar Box Start -->
<div id="accept-cookies" class="cookie-bar-box" [hidden]="hideCookieDialog">
  <div class="cookie-box">
    <div class="cookie-image">
      <img src="./assets/images/cookie-bar.png" class="blur-up lazyload" alt="">
      <h2>{{ 'cookies.title' | translate }}</h2>
    </div>

    <div class="cookie-contain">
      <h5 class="text-content">{{ 'cookies.we_use_cookies' | translate }}</h5>
    </div>
  </div>

  <div class="button-group">
    <a target="_blank" [href]="privacyPoliceUrl" class="btn privacy-button">{{ 'cookies.privacy_police' | translate }}</a>
    <button class="btn ok-button" (click)="acceptCookies()">{{ 'cookies.ok' | translate }}</button>
  </div>
</div>
<!-- Cookie Bar Box End -->