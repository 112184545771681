import { Injectable } from "@angular/core";
import { SlugfyService } from "src/app/shared/services/slugfy.service";
import { UtilsService } from "src/app/shared/services/utils.service";
import { Product, TagsProducts } from "../models/product";
import { TypePayment } from "../models/type-payment";

@Injectable()
export class ProductAdapter {

  constructor(
    private utilsService: UtilsService,
    private slugfyService: SlugfyService
  ) { }

  adapter(raw: any): Product {
    const product: Product = {
      id: raw['idClassificador'] || null,
      name: raw['noClassificador'] || null,
      nameLink: this.slugfyService.transform(raw['noClassificador']),
      imageUrl: null,
      value: raw['vlProduto'] || null,
      quantity: raw['nuQtdEstoque'] || null,
      description: raw['dsClassificador'] || null,
      quantityViewCart: 0
    };

    if (raw['categoria']) {
      product.category = {
        name: raw['categoria'].noDado,
        type: raw['categoria'].sgDado
      } || null;
    }

    if (raw['tags'] && raw['tags'].some((item: any) => item.sgDado)) {
      product.tags = raw['tags'].filter((item: any) => item.sgDado).map((tag: any) => {
        return {
          type: tag.sgDado,
          name: tag.noDado
        } as TagsProducts;
      });
    }

    // add image
    if (raw['imagens'] && raw['imagens'].length) {
      product.imageUrl = this.utilsService.imageUrl(raw.imagens[0].contentId);
    }

    return this.validate(product);
  }

  public validate(product: Product): Product {
    if (!product.id || !product.name || !product.nameLink || !product.value) {
      throw new Error('Error creating product, all attributes must be filled in.');
    }
    return product;
  }

  adapterPayment(raw: any) {
    const payment: TypePayment = {
      id: raw['idTpFormaPgtoConfig'] || null,
      name: raw['dsFormaPgto'] || null,
      appPayment: raw['flPgtoApp'] || null,
      deliveryPayment: raw['flPgtoEntrega'] || null,
      typePayment: raw['tpLojaFormaPgto'] || null,
      imageUrl: null,
      delivery: raw['flPgtoEntrega'] || null
    }

    // add image
    if (raw['contentId']) {
      payment.imageUrl = this.utilsService.imageUrl(raw['contentId']);
    }

    return payment;
  }

}
