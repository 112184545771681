import { UtilsService } from 'src/app/shared/services/utils.service';
import { Company } from 'src/app/core/models/company';
import { CompanyService } from 'src/app/core/services/company.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, retry } from 'rxjs';
import { CheckoutAdapter } from '../adapters/checkout.adapter';
import {  throwError } from 'rxjs';
import { Fare, ListSales, Sale, SalesAdressPartner } from '../models/taxes';



@Injectable()
export class CheckoutService {

  private company: Company | null;
  private readonly CHECKOUT_URL = '/vendasLoja';
  private readonly PARTNER_URL = '/pessoaLoja';
  private readonly EMIS_GPO = '/emisGpo';
  private readonly MULTI_URL = '/emis_gpo_webframe/getToken';
  private readonly Mutil_JUP_URL = '/emis_gpo_points_of_sale';
  handleError: any;

  constructor(
    private oHttp: HttpClient,
    private oUtilsService: UtilsService,
    private oCheckoutAdapter: CheckoutAdapter,
    private oCompanyService: CompanyService
  ) {
    this.company = this.oCompanyService.company;
   }

  getTaxesByRequest(idLocale: number, list: Array<any>): Observable<Fare> {
    const oParams = {} as any;

    oParams['idLocalidade'] = idLocale;
    oParams['classificadores'] = JSON.stringify(list);
    oParams['idParceiro'] = null;
    oParams['idEndereco'] = null;

    return this.oHttp.post<any>(`${this.CHECKOUT_URL}/getTotaisVenda`, oParams).pipe(map(item => {
      if (item) {
        return this.oCheckoutAdapter.adapter(item);
      }
      return {} as Fare;
    }));
  }

  getTokenGPOEmis(price:any, referenceEmisGpo:any): Observable<any> {
    const oParams = {} as any;

    oParams['nuReferencia'] = referenceEmisGpo;
    oParams['vlMontante'] = price;

    return this.oHttp.get<any>(`${this.MULTI_URL}`, { params: oParams }).pipe(map(data => {
      if (data) {
        return data
      }
      return {} as any;
    }));
  }

  createSaleByRequest(sale: Sale): Observable<any> {
    const oParams = {} as any;

    oParams['idParceiro'] = null;
    oParams['dsFormaPgto'] = sale.typePayment;
    oParams['nuPrazoMaximo'] = sale.maxTime;
    oParams['nuPrazoMinimo'] = sale.minTime;
     // todo
    oParams['idPesEnderecoParceiro'] = sale.idAddressPartner;
    oParams['idPesEnderecoCliente'] = sale.idAddressClient;
    oParams['nuDocumentoFiscal'] = sale.nuDocumentoFiscal;
    oParams['nuDocumento'] = sale.document;
    oParams['classificadores'] = JSON.stringify(sale.list.map((item: ListSales) => {
      return {
        idClassificador: item.id,
        nuQuantidade: item.quantity,
        vlImposto: item.vlImposto,
        vlTaxaCambio: item.valueTaxe,
        vlUnitario: item.vlUnitario || item.value,
        vlUnitarioFinal: item.vlUnitarioFinal
      };
    }));
    oParams['vlTotalProdutos'] = sale.valueProducts;
    oParams['nuLatitude'] = sale.latitude;
    oParams['nuLongitude'] = sale.longitude;
    oParams['flPgtoEntrega'] = sale.paymentDelivery;
    oParams['tpLocalEntrega'] = sale.typeLocalDelivery;
    return this.oHttp.post(`${this.CHECKOUT_URL}/geraVenda`, oParams).pipe(
      map(res => res),
      catchError(error =>{
        return error
      }));
  }

  paymentMulti(userData: any): Observable<any> {
    return this.oHttp.post(`${this.Mutil_JUP_URL}/insPayment`, userData).pipe(
      retry(-1), 
      map(res => res),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }


  getAdressPartner() {
    const oParams = {} as any;
    oParams['sgPessoaSiaf'] = this.company?.id;

    return this.oHttp.get(`${this.PARTNER_URL}/lstParceiroDet`, { headers: this.oUtilsService.requestPublic(true), params: oParams }).pipe(
      map((oRet: any) => {
        if (oRet) {
          return this.oCheckoutAdapter.adapterAdressPartner(oRet);
        }
        return {} as SalesAdressPartner;
      }));
  }

  checkPayment(referenceEmisGpo:any, idContrato:any) {
    const oParams = {} as any;
    oParams['nuReferencia'] = referenceEmisGpo;
    if (idContrato) {
      oParams['idContrato'] = idContrato;
    }
    return this.oHttp.get<any>(`${this.EMIS_GPO}/checkTransaction`, { params: oParams }).pipe(map(data => {
      if (data) {
        return data
      }
    }));
  }

}
