<!-- Breadcrumb Section Start -->
<section class="breadscrumb-section pt-0">
  <div class="container-fluid-lg">
    <div class="row">
      <div class="col-12">
        <div class="breadscrumb-contain">
          <h2>{{'products.view_cart_products' | translate}}</h2>
          <nav>
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item">
                <a [routerLink]="['/']" routerLinkActive="router-link-active" class="cursor-pointer">
                  <i class="fa-solid fa-house"></i>
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{'products.view_cart_products' |
                translate}}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Breadcrumb Section End -->

<!-- Cart Section Start -->
<section class="cart-section section-b-space">
  <div class="container-fluid-lg">
    <div class="row g-sm-5 g-3">
      <div class="col-xxl-9">
        <div class="cart-table">
          <form [formGroup]="oForm">
            <div class="table-responsive-xl">
              <table class="table">
                <tbody>
                  <ng-container formArrayName="products">
                    <tr *ngFor="let item of products.controls; let i = index;" class="product-box-contain">
                      <div [formGroupName]="i">
                        <td class="product-detail" style="vertical-align: top;">
                          <div class="product border-0">
                            <div class="product-image">
                              <a [routerLink]="['/product-detail/'+item.get('id')?.value]"
                                class="text-center w-100 d-block">
                                <img [routerLink]="['/product-detail/'+item.get('id')?.value]"
                                  *ngIf="getImageUrl(item.get('id')?.value)" [src]="getImageUrl(item.get('id')?.value)"
                                  class="img-fluid blur-up lazyload" style="height: 60px">
                                <i *ngIf="!getImageUrl(item.get('id')?.value)"
                                  class="fa fa-shopping-cart img-fluid blur-up lazyload"
                                  style="color: #eee;font-size: 45px;margin-top: 0;padding: 0 9px;"></i>
                              </a>
                            </div>
                          </div>
                        </td>

                        <td class="title-product w-50">
                          <h4 class="table-title text-content">{{'products.title' |
                            translate}}</h4>
                          <a [routerLink]="['/product-detail/'+item.get('id')?.value]">
                            <h5 class="theme-color">{{item.get('name')?.value }}</h5>
                          </a>
                        </td>

                        <td class="price">
                          <h4 class="table-title text-content">{{'products.price' |
                            translate}}</h4>
                          <h5 class="theme-color">
                            {{item.get('value')?.value.toLocaleString('pt-AO', {
                            minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} AOA
                          </h5>
                        </td>

                        <td class="quantity">
                          <h4 class="table-title text-content">{{'products.quantity' |
                            translate}}
                          </h4>
                          <div class="quantity-price">
                            <div class="cart_qty">
                              <div class="input-group">
                                <button (click)="onChangeCount(i, 'LESS')" type="button" class="btn qty-left-minus"
                                  data-type="minus" data-field="">
                                  <i class="fa fa-minus ms-0" aria-hidden="true"></i>
                                </button>

                                <input formControlName="quantityViewCart"
                                  [value]="qtdLimit(i, item.get('quantityStock'))" #quantityViewCart
                                  id="quantityViewCart" class="form-control input-number qty-input" type="number"
                                  name="quantityViewCart">

                                <button (click)="onChangeCount(i, 'MORE')" type="button" class="btn qty-right-plus"
                                  data-type="plus" data-field="">
                                  <i class="fa fa-plus ms-0" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td class="subtotal">
                          <h4 class="table-title text-content">{{'products.total' |
                            translate}}</h4>
                          <h5 class="theme-color">
                            {{getSumLineProduct(item.get('value')?.value,
                            quantityViewCart.value).toLocaleString('pt-AO', {
                            minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} AOA
                          </h5>
                        </td>

                        <td class="save-remove">
                          <h4 class="table-title text-content">{{'common.actions.action' |
                            translate}}
                          </h4>
                          <a (click)="onRemove(item.get('idViewCart')?.value, item.get('id')?.value)" class="remove close_button "
                            href="javascript:void(0)">
                            <i class="fa fa-trash"></i>
                          </a>
                        </td>
                      </div>
                    </tr>
                    <tr *ngIf="!products.length" class="product-box-contain">
                      <td class="product-detail">
                        <div class="product border-0">
                          <span style="font-size: 16px;" class="text-content">{{'checkout.your_shopping_cart_empty' |
                            translate}}</span>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="summery-box p-sticky">
          <div class="summery-header">
            <h3>{{'products.summary_cart' | translate}}</h3>
          </div>

          <div class="summery-contain">
            <ul>
              <li>
                <h4>{{'products.sub_total' | translate}}</h4>
                <h4 class="price">{{getTotalValueProducts().toLocaleString('pt-AO', {
                  minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} AOA</h4>
              </li>
            </ul>
          </div>

          <ul class="summery-total">
            <li class="list-total border-top-0">

            </li>
          </ul>

          <div class="button-group cart-button">
            <ul>
              <li *ngIf="aCartProducts?.length">
                <button (click)="onLoad()" class="btn btn-animation proceed-btn fw-bold">
                  {{'products.process_checkout' | translate}}
                </button>
              </li>

              <li>
                <button [routerLink]="['/']" routerLinkActive="router-link-active"
                  class="btn btn-light shopping-button text-dark">
                  <i class="fa-solid fa-arrow-left-long"></i>
                  {{'products.return_home' | translate}}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Cart Section End -->
