import { Component } from '@angular/core';
import { CurrencyService } from './shared/services/currency.service';
import { LanguageService } from './shared/services/language.service';
import { TokenService } from './core/services/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    private languageService: LanguageService,
    private currencyService: CurrencyService,
    private tokenService: TokenService
  ) {
    this.languageService.init();
    this.currencyService.init();
    this.tokenService.init();
  }
  spinnerConfig = {
    bdColor: 'rgba(0,0,0,0.6)',
    size: 'default',
    color: '#88add2',
    type: 'ball-scale-multiple',
    fullScreen: true
  };

  showCartOptions = false;

  toggleCartOptions() {
    this.showCartOptions = !this.showCartOptions;
  }
}
