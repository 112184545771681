import { Injectable } from "@angular/core";
import { UserContacts } from "../models/user-contacts";

@Injectable()
export class UserContactsAdapter {

  constructor() { }

  adapter(raw: any): UserContacts {
    const contact: UserContacts = {
      id: raw['idContato'] || null,
      type: {
        type: raw['tpContato'].codigo,
        name: raw['tpContato'].descricao
      } || null,
      description: raw['dsContato'] || null,
    };
    return contact;
  }

}
