import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserRepository } from '../repository/user.repository';
import { LanguageService } from 'src/app/shared/services/language.service';
import { TokenRepository } from '../repository/token.repository';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { settings } from 'src/settings';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  private readonly prefixFull = 'http';

  private readonly prefixRelative = './';

  constructor(
    private userRepository: UserRepository,
    private languageService: LanguageService,
    private tokenRepository: TokenRepository,
    private utilsService: UtilsService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const user = this.userRepository.getUser();
    let headers: HttpHeaders;
    headers = req.headers;
    headers = headers.set('Accept-Language', this.languageService.getLanguage().toLowerCase());
    const isPublicRequest = headers.has(this.utilsService.publicHeaderKey);

    if (user != null && user.credential.access_token != null && !headers.get('Authorization') && !isPublicRequest) {
      headers = headers.set('Authorization', `${user.credential.token_type} ${user.credential.access_token}`);
    } else if (!headers.get('Authorization') && isPublicRequest) {
      const token = this.tokenRepository.getToken();
      if (token != null && token.access_token != null) {
        headers = headers.delete(this.utilsService.publicHeaderKey);
        headers = headers.set('Authorization', `${token.token_type} ${token.access_token}`);
      }
    }

    if (!headers.has('Content-Type') && !req.url.includes('assets')) {
      switch (req.method) {
        case 'GET':
          headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          break;
        case 'DELETE':
          headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          break;
        default:
          headers = headers.set('Content-Type', 'application/json; charset=UTF-8');
          break;
      }
    }

    if (!req.url.startsWith(this.prefixFull) && !req.url.startsWith(this.prefixRelative)) {
      return next.handle(req.clone({
        headers: headers,
        url: settings.endpoint.url + req.url
      }));
    }

    return next.handle(req.clone({ headers: headers }));
  }

}
